import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-xml';
import 'ace-builds/src-noconflict/theme-chrome';
import 'ace-builds/src-noconflict/theme-gruvbox';
import 'ace-builds/src-noconflict/ext-language_tools';
import 'ace-builds/webpack-resolver';
import './index.css';

import { SearchOutlined } from '@ant-design/icons';
import searchBox from 'ace-builds/src-noconflict/ext-searchbox';
import { Button, Checkbox, Select, Tooltip } from 'antd';
import debounce from 'lodash.debounce';
import React, { createRef, RefObject } from 'react';
import AceEditor from 'react-ace';
import { connect } from 'react-redux';

import { getDarkMode } from '../../store/system/selectors';
import { changeFormat, getFormat, getXML, parseXML } from '../XZuFiResult/duck';

const { Option } = Select;

type ValidatorProps = {
  dispatch: Function;
  xml: string;
  format: string;
  darkMode: boolean;
}

type ValidatorState = {
  lineWrap: boolean;
}


class Validator extends React.Component<ValidatorProps, ValidatorState> {

  private ro: ResizeObserver | null = null;

  private readonly aceRef: RefObject<AceEditor>;

  private readonly _debounceUpdate: Function;

  public constructor(props: ValidatorProps) {
    super(props);

    this.state = {
      lineWrap: false,
    };

    this.aceRef = createRef();

    this.onXMLChange = this.onXMLChange.bind(this);
    this.toggleLineWrap = this.toggleLineWrap.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);

    this._debounceUpdate = debounce(
      (xml: string) => {
        this.props.dispatch(parseXML(xml));
      },
      1000,
    );
  }

  public componentDidMount() {
    const htmlInput = document.getElementById('input-html');

    if (htmlInput === null || this.ro !== null) {
      return;
    }

    this.ro = new ResizeObserver(_ => {
      window.dispatchEvent(new Event('resize'))
    })

    this.ro.observe(htmlInput);
  }

  private onXMLChange(xml: string) {
    this._debounceUpdate(xml);
  }

  private toggleSearch() {
    const aceEditor = this.aceRef.current;

    if (aceEditor === null) {
      return;
    }

    if (aceEditor.editor.searchBox === undefined) {
      searchBox.Search(aceEditor.editor, false);

      return;
    }

    if (!aceEditor.editor.searchBox.active) {
      aceEditor.editor.searchBox.show();

      return;
    }

    aceEditor.editor.searchBox.hide();
  }

  private toggleLineWrap() {
    this.setState({
      lineWrap: !this.state.lineWrap,
    });
  }

  private onFormatChange(format: string) {
    this.props.dispatch(changeFormat(format));

    if (this.props.xml.length === 0) {
      return;
    }

    this.props.dispatch(parseXML(this.props.xml));
  }

  public render() {
    let editorTheme = 'chrome';
    if (this.props.darkMode) {
      editorTheme = 'gruvbox';
    }

    return (
      <div className="input-html" id="input-html">
        <div className="toolbar">
          <div>
            <span className="form-label">Format</span>
            <Select
              className="form"
              defaultValue={this.props.format}
              size="small"
              onSelect={(format: string) => {
                this.onFormatChange(format);
              }}
            >
              <Option value="auto">automatisch</Option>
              <Option value="xzufi2.1">XZuFi 2.1</Option>
              <Option value="xzufi2.2">XZuFi 2.2</Option>
            </Select>
            <Checkbox
              onChange={() => {
                this.toggleLineWrap();
              }}
              checked={this.state.lineWrap}
            >autom. Zeilenumbruch</Checkbox>
          </div>
          <div />
          <div>
            <Tooltip title="Suche (Strg + F)">
              <Button
                type="text"
                className="searchButton"
                size="small"
                onClick={() => {
                  this.toggleSearch();
                }}
              >
                <SearchOutlined />
              </Button>
            </Tooltip>
          </div>
        </div>
        <AceEditor
          mode="xml"
          theme={editorTheme}
          width="100%"
          height="100%"
          showPrintMargin={false}
          ref={this.aceRef}
          value={this.props.xml}
          className="xzufi-input"
          onChange={(value: string) => {
            this.onXMLChange(value);
          }}
          wrapEnabled={this.state.lineWrap}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
          }}
        />
      </div>
    );
  }
}

export default connect((state: any) => ({
  xml: getXML(state),
  format: getFormat(state),
  darkMode: getDarkMode(state),
}))(Validator);
