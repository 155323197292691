import { ValidatorAPIResponse } from '../../../services/validator';

export const LOADING = 'actions/xml/LOADING';
export const CHANGE_XML = 'actions/xml/CHANGE_XML';
export const CHANGE_FORMAT = 'actions/xml/CHANGE_FORMAT';
export const VALIDATOR_RESULT = 'actions/xml/VALIDATOR_RESULT';
export const VALIDATOR_FAILURE = 'actions/xml/VALIDATOR_FAILURE';

export function loading() {
  return {
    type: LOADING,
    payload: {},
  };
}

export function changeXML(xml: string) {
  return {
    type: CHANGE_XML,
    payload: {
      xml,
    },
  };
}

export function changeFormat(format: string) {
  return {
    type: CHANGE_FORMAT,
    payload: {
      format,
    },
  };
}

export function validatorResult(result: ValidatorAPIResponse) {
  return {
    type: VALIDATOR_RESULT,
    payload: {
      result,
    },
  };
}

export function validatorFailure(reason: string) {
  return {
    type: VALIDATOR_FAILURE,
    payload: {
      reason,
    },
  };
}
