import { RuleDescriptions } from './Rules';
import { ValidationError } from './ValidationError';
import { ZuFi } from './ZuFi';

export type ValidatorAPIResponse = {
  errors: ValidationError[];
  model: ZuFi;
  format: string;
};

export async function validate(xml: string, format: string): Promise<ValidatorAPIResponse> {
  return fetch(`${window.env.API_URL}/api/v1/validate?format=${format}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/xml',
    },
    body: xml,
  }).then(async (res) => {
    return res.json();
  })
}

export async function version(): Promise<string> {
  return fetch(`${window.env.API_URL}/api/v1/version`, {
    method: 'GET',
  }).then(async (res) => {
    if (res.status !== 200) {
      return '';
    }

    return res.text();
  });
}

export type RulesAPIResponse = {
  competences: RuleDescriptions;
  orgUnits: RuleDescriptions;
  onlineServices: RuleDescriptions;
  services: RuleDescriptions;
};

export async function rules(): Promise<any> {
  return fetch(`${window.env.API_URL}/api/v1/rules`, {
    method: 'GET',
  }).then(async (res) => {
    return res.json();
  });
}
