import { Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { Author } from '../services/validator/ZuFi';
import PrettyValueOrDefault from './PrettyValueOrDefault';

type AuthorProps = {
  author: Author;
};

type AuthorState = {

}

type AuthorPropDetailsType = {
  id: TooltipDetails;
  name: TooltipDetails;
  secondaryID: TooltipDetails;
  shortDescription: TooltipDetails;
  description: TooltipDetails;
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const AuthorPropDetails: AuthorPropDetailsType = {
  id: {
    label: 'ID',
    tooltip: {
      title: 'id [1]',
      content: 'Eindeutige ID des Herausgebers',
    },
  },
  secondaryID: {
    label: 'Sekundäre ID',
    tooltip: {
      title: 'idSekundaer [0…1]',
      content: 'Sekundäre IDs',
    },
  },
  name: {
    label: 'Bezeichnung',
    tooltip: {
      title: 'Bezeichnung [1…n]',
      content: 'Die Bezeichnung bzw. der Name, z.B. "Main-Taunus-Kreis"',
    },
  },
  shortDescription: {
    label: 'Kurzbezeichnung',
    tooltip: {
      title: 'kurzbezeichnung [0…1]',
      content: 'Eine kurze prägnante Bezeichnung, z.B. "MTK".',
    },
  },
  description: {
    label: 'Beschreibung',
    tooltip: {
      title: 'beschreibung [0…1]',
      content: 'Eine textuelle Beschreibung des Herausgebers.',
    },
  },
};

class AuthorComponent extends React.Component<AuthorProps, AuthorState> {
  private descriptionProps(key: string): any {
    const details: TooltipDetails = AuthorPropDetails[key as keyof AuthorPropDetailsType];

    if (details === undefined) {
      return {};
    }

    return {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };
  }

  public render(): ReactNode {
    return (
      <div className="address">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('id')}>
            <PrettyValueOrDefault value={this.props.author.id} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('name')}>
            <PrettyValueOrDefault value={this.props.author.name} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('secondaryID')}>
            <PrettyValueOrDefault value={this.props.author.secondaryID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('shortDescription')}>
            <PrettyValueOrDefault value={this.props.author.shortDescription} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('description')}>
            <PrettyValueOrDefault value={this.props.author.description} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default AuthorComponent;
