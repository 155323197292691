import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { Address, Communication, ContactPerson } from '../services/validator/ZuFi';
import AddressComponent from './Address';
import AuthorComponent from './Author';
import CommunicationComponent from './Communication';
import DefaultValue from './DefaultValue';
import PrettyValueOrDefault from './PrettyValueOrDefault';

const { Panel } = Collapse;

type ContactPersonProps = {
  contactPerson: ContactPerson;
  index: number;
  searchTerm: string;
};

type ContactPersonState = {
  id: TooltipDetails;
  secondaryID: TooltipDetails;
  salutation: TooltipDetails;
  title: TooltipDetails;
  firstName: TooltipDetails;
  lastName: TooltipDetails;
  position: TooltipDetails;
  officeHours: TooltipDetails;
  room: TooltipDetails;
  address: TooltipDetails;
  communication: TooltipDetails;
  website: TooltipDetails;
}

type ContactPersonPropDetailsType = {
  id: TooltipDetails;
  secondaryID: TooltipDetails;
  salutation: TooltipDetails;
  title: TooltipDetails;
  firstName: TooltipDetails;
  lastName: TooltipDetails;
  position: TooltipDetails;
  officeHours: TooltipDetails;
  room: TooltipDetails;
  address: TooltipDetails;
  communication: TooltipDetails;
  website: TooltipDetails;
  validFrom: TooltipDetails;
  validUntil: TooltipDetails;
  author: TooltipDetails;
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const ContactPersonPropDetails: ContactPersonPropDetailsType = {
  id: {
    label: 'ID',
    tooltip: {
      title: 'id [1]',
      content: 'Eindeutige ID der Kontaktperson',
    },
  },
  secondaryID: {
    label: 'Sekundäre ID',
    tooltip: {
      title: 'id [0…n]',
      content: 'Sekundäre IDs der Kontaktperson',
    },
  },
  salutation: {
    label: 'Anrede',
    tooltip: {
      title: 'anrede [0…1]',
      content: 'Anrede der Person, z.B. "Frau".',
    },
  },
  title: {
    label: 'Titel',
    tooltip: {
      title: 'titel [0…n]',
      content: 'Titel der Person, z.B. "Dr."',
    },
  },
  firstName: {
    label: 'Vorname',
    tooltip: {
      title: 'vorname [0…1]',
      content: 'Vorname der Person.',
    },
  },
  lastName: {
    label: 'Familienname',
    tooltip: {
      title: 'familienname [0…1]',
      content: 'Familien- bzw. Nachname der Person.',
    },
  },
  position: {
    label: 'Position',
    tooltip: {
      title: 'position [0…n]',
      content: 'Angaben zur Position/Funktion der Person.',
    },
  },
  officeHours: {
    label: 'Sprechzeiten',
    tooltip: {
      title: 'sprechzeiten [0…n]',
      content: 'Angaben zu Sprechzeiten der Person.',
    },
  },
  room: {
    label: 'Raum',
    tooltip: {
      title: 'raum [0…1]',
      content: 'Angaben zum Raum der Person.',
    },
  },
  address: {
    label: 'Anschrift',
    tooltip: {
      title: 'anschrift [0…n]',
      content: 'Die Angabe ist in der Regel nicht erforderlich. Hier sollen nur von der Organisationseinheit, welcher die Kontaktperson zugeordnet ist, abweichende oder spezielle Anschriften angegeben werden.',
    },
  },
  communication: {
    label: 'Kommunikation',
    tooltip: {
      title: 'kommunikation [0…n]',
      content: 'Angabe von Kommunikationskanälen der Kontaktperson. Dies ist z.B. die persönliche E-Mail oder die Telefonnummer mit konkreter Durchwahl. Allgemeine Kanäle der übergeordneten Organisationseinheit sollen nicht wiederholt angegeben werden.',
    },
  },
  website: {
    label: 'Internetadresse',
    tooltip: {
      title: 'internetadresse [0…n]',
      content: 'Angabe einer speziellen Internetadresse, welche weitere Informationen zur Kontaktperson bereitstellt. Die offizielle Internetadresse der übergeordneten Organisationseinheit soll hier nicht wiederholt angegeben werden.',
    },
  },
  validFrom : {
    label: 'Gültigkeitsbeginn',
    tooltip: {
      title: 'gueltigkeit > beginn [0…n]',
      content: 'Beginn der Gültigkeit',
    },
  },
  validUntil : {
    label: 'Gültigkeitsende',
    tooltip: {
      title: 'gueltigkeit > ende [0…n]',
      content: 'Ende der Gültigkeit',
    },
  },
  author : {
    label: 'Herausgeber',
    tooltip: {
      title: 'herausgeber [0…1]',
      content: 'Der Herausgeber',
    },
  },
};

class ContactPersonComponent extends React.Component<ContactPersonProps, ContactPersonState> {
  private descriptionProps(key: string): any {
    const details: TooltipDetails = ContactPersonPropDetails[key as keyof ContactPersonPropDetailsType];

    if (details === undefined) {
      return {};
    }

    let props: any = {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };

    if (this.props.searchTerm === '') {
      return props;
    }

    const value = this.props.contactPerson[key as keyof ContactPerson];
    const valueMatching = JSON.stringify(value).toLowerCase().includes(this.props.searchTerm.toLowerCase());
    const labelMatching = details.label.toLowerCase().includes(this.props.searchTerm.toLowerCase());

    if (!labelMatching && !valueMatching) {
      props = {
        ...props,
        style: {
          display: 'none',
        },
      };
    }

    return props;
  }

  public render(): ReactNode {
    return (
      <div className="address">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('id')}>
            <PrettyValueOrDefault value={this.props.contactPerson.id} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('secondaryID')}>
            <PrettyValueOrDefault value={this.props.contactPerson.secondaryID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('salutation')}>
            <PrettyValueOrDefault value={this.props.contactPerson.salutation} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('title')}>
            <PrettyValueOrDefault value={this.props.contactPerson.title} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('firstName')}>
            <PrettyValueOrDefault value={this.props.contactPerson.firstName} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('lastName')}>
            <PrettyValueOrDefault value={this.props.contactPerson.lastName} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('position')}>
            <PrettyValueOrDefault value={this.props.contactPerson.position} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('officeHours')}>
            <PrettyValueOrDefault value={this.props.contactPerson.officeHours} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('room')}>
            <PrettyValueOrDefault value={this.props.contactPerson.room} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('address')}>
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              ghost
            >
              { this.props.contactPerson.address.map((address: Address, addressIndex: number) => (
                <Panel
                  header={(
                    <span>{`Anschrift ${addressIndex + 1}`}</span>
                  )}
                  key={`addr-${addressIndex}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <AddressComponent address={address} key={addressIndex} index={addressIndex} searchTerm={this.props.searchTerm} />
                  </div>
                </Panel>
              )) }
            </Collapse>
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('communication')}>
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              ghost
            >
              { this.props.contactPerson.communication.map((communication: Communication, index: number) => (
                <Panel
                  header={(
                    <span>{
                      communication.identifier
                        ? `${communication.identifier} (Kommunikation ${index + 1})`
                        : `Kommunikation ${index + 1}`
                    }</span>
                  )}
                  key={`comm-${index}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <CommunicationComponent communication={communication} key={index} index={index} />
                  </div>
                </Panel>
              )) }
            </Collapse>
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('website')}>
            <PrettyValueOrDefault value={this.props.contactPerson.website} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validFrom')}>
            <PrettyValueOrDefault value={this.props.contactPerson.validFrom} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validUntil')}>
            <PrettyValueOrDefault value={this.props.contactPerson.validUntil} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('author')}>
            { typeof this.props.contactPerson.author === 'string'
              ? <DefaultValue />
              : <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                ghost
              >
                <Panel
                  header={(
                    <span>{
                      this.props.contactPerson.author.name
                        ? `${this.props.contactPerson.author.name} (Herausgeber)`
                        : 'Herausgeber'
                    }</span>
                  )}
                  key={`author-${this.props.contactPerson.id}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <AuthorComponent author={this.props.contactPerson.author} />
                  </div>
                </Panel>
              </Collapse>
            }
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default ContactPersonComponent;
