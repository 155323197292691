import { Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { TextCustom } from '../services/validator/ZuFi';
import PrettyValueOrDefault from './PrettyValueOrDefault';

type TextCustomProps = {
  textCustom: TextCustom;
};

type TextCustomState = {

};

type TextCustomPropDetailsType = {
  name: TooltipDetails,
  content: TooltipDetails,
  position: TooltipDetails,
  id: TooltipDetails,
  secondaryID: TooltipDetails,
  validFrom: TooltipDetails,
  validUntil: TooltipDetails,
  typeHint: TooltipDetails,
  typeID: TooltipDetails,
  typeSecondaryID: TooltipDetails,
  url: TooltipDetails,
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const TextCustomPropDetails: TextCustomPropDetailsType = {
  name: {
    label: 'Bezeichnung',
    tooltip: {
      title: 'individuellesTextmodultyp > bezeichnung',
      content: undefined,
    },
  },
  content: {
    label: 'Inhalt',
    tooltip: {
      title: 'inhalt',
      content: undefined,
    },
  },
  position: {
    label: 'Position Darstellung',
    tooltip: {
      title: 'positionDarstellung',
      content: undefined,
    },
  },
  id: {
    label: 'ID',
    tooltip: {
      title: 'id',
      content: undefined,
    },
  },
  secondaryID: {
    label: 'Sekundäre ID',
    tooltip: {
      title: 'idSekundaer',
      content: undefined,
    },
  },
  validFrom: {
    label: 'Gültig ab',
    tooltip: {
      title: 'gueltigkeit > beginn',
      content: undefined,
    },
  },
  validUntil: {
    label: 'Gültig bis',
    tooltip: {
      title: 'gueltigkeit > ende',
      content: undefined,
    },
  },
  typeHint: {
    label: 'Hinweis zum Typ',
    tooltip: {
      title: 'individuellesTextmodultyp > hinweis',
      content: undefined,
    },
  },
  typeID: {
    label: 'ID des Typs',
    tooltip: {
      title: 'individuellesTextmodultyp > id',
      content: undefined,
    },
  },
  typeSecondaryID: {
    label: 'Sekundäre ID des Typs',
    tooltip: {
      title: 'individuellesTextmodultyp > idSekundaer',
      content: undefined,
    },
  },
  url: {
    label: 'Weiterführender Link',
    tooltip: {
      title: 'weiterfuehrenderLink',
      content: undefined,
    },
  },
};

class TextCustomComponent extends React.Component<TextCustomProps, TextCustomState> {
  private descriptionProps(key: string): any {
    const details: TooltipDetails = TextCustomPropDetails[key as keyof TextCustomPropDetailsType];

    if (details === undefined) {
      return {};
    }

    return {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };
  }

  public render(): ReactNode {
    return (
      <div className="leistung">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('name')}>
            <PrettyValueOrDefault value={this.props.textCustom.name} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('content')}>
            <PrettyValueOrDefault value={this.props.textCustom.content} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('position')}>
            <PrettyValueOrDefault value={this.props.textCustom.position} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('id')}>
            <PrettyValueOrDefault value={this.props.textCustom.id} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('secondaryID')}>
            <PrettyValueOrDefault value={this.props.textCustom.secondaryID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validFrom')}>
            <PrettyValueOrDefault value={this.props.textCustom.validFrom} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validUntil')}>
            <PrettyValueOrDefault value={this.props.textCustom.validUntil} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('typeHint')}>
            <PrettyValueOrDefault value={this.props.textCustom.typeHint} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('typeID')}>
            <PrettyValueOrDefault value={this.props.textCustom.typeID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('typeSecondaryID')}>
            <PrettyValueOrDefault value={this.props.textCustom.typeSecondaryID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('url')}>
            <PrettyValueOrDefault value={this.props.textCustom.url} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default TextCustomComponent;
