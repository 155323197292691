export function getRulesState(state: any): any {
  return state.rules;
}

export function getLoading(state: any): boolean {
  return getRulesState(state).loading;
}

export function getRules(state: any): any {
  return getRulesState(state).rules;
}

export function getRulesError(state: any): string {
  return getRulesState(state).reason;
}
