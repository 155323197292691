import { Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { Document } from '../services/validator/ZuFi';
import PrettyValueOrDefault from './PrettyValueOrDefault';

type DocumentProps = {
  document: Document;
}

type DocumentState = {

}

type DeadlinePropDetailsType = {
  type: TooltipDetails,
  name: TooltipDetails,
  description: TooltipDetails,
  referenceFormID: TooltipDetails,
  signature: TooltipDetails,
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const DeadlinePropDetails: DeadlinePropDetailsType = {
  type: {
    label: 'Typ',
    tooltip: {
      title: 'typ',
      content: undefined,
    },
  },
  name: {
    label: 'Bezeichnung',
    tooltip: {
      title: 'bezeichnung',
      content: undefined,
    },
  },
  description: {
    label: 'Beschreibung',
    tooltip: {
      title: 'beschreibung',
      content: undefined,
    },
  },
  referenceFormID: {
    label: 'ID des Referenz-Formulars',
    tooltip: {
      title: 'referenzFormularID',
      content: undefined,
    },
  },
  signature: {
    label: 'Notwendigkeit einer Signatur',
    tooltip: {
      title: 'angabeSignatur',
      content: undefined,
    },
  },
};

class DocumentComponent extends React.Component<DocumentProps, DocumentState> {
  private descriptionProps(key: string): any {
    const details: TooltipDetails = DeadlinePropDetails[key as keyof DeadlinePropDetailsType];

    if (details === undefined) {
      return {};
    }

    return {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };
  }

  public render(): ReactNode {
    return (
      <div className="leistung">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('type')}>
            <PrettyValueOrDefault value={this.props.document.type} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('name')}>
            <PrettyValueOrDefault value={this.props.document.name} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('description')}>
            <PrettyValueOrDefault value={this.props.document.description} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('referenceFormID')}>
            <PrettyValueOrDefault value={this.props.document.referenceFormID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('signature')}>
            <PrettyValueOrDefault value={this.props.document.signature} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default DocumentComponent;
