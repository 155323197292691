import { Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { Address } from '../services/validator/ZuFi';
import PrettyValueOrDefault from './PrettyValueOrDefault';

type AddressProps = {
  address: Address;
  index: number;
  searchTerm: string;
};

type AddressState = {

}

type AddressPropDetailsType = {
  zipCode: TooltipDetails;
  location: TooltipDetails;
  type: TooltipDetails;
  street: TooltipDetails;
  houseNumber: TooltipDetails;
  mailbox: TooltipDetails;
  locationID: TooltipDetails;
  administrativeCoding: TooltipDetails;
  addition: TooltipDetails;
  geoCoding: TooltipDetails;
  directionURL: TooltipDetails;
  id: TooltipDetails;
  validFrom: TooltipDetails;
  validUntil: TooltipDetails;
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const AddressPropDetails: AddressPropDetailsType = {
  type: {
    label: 'Typ',
    tooltip: {
      title: 'typ [0…1]',
      content: (
        <div>
          Ein Anschrifttyp dient zur näheren Bestimmung der Art von Anschriften. Unterstützte Typen sind:<br /><br />
          001 → Allgemeine Hausanschrift<br />
          002 → Eine Besucheranschrift ist eine Anschrift für Besucher<br />
          003 → Postfach ist eine besondere Anschrift speziell für Post<br />
          004 → Großempfänger-Postfach ist eine spezielle Anschrift für Post bei Großkunden<br />
          005 → Lieferanschrift ist eine besondere Anschrift zur Anlieferung von Waren<br /><br />
          Siehe: urn:de:xzufi:codeliste:anschrifttyp
        </div>
      ),
    },
  },
  street: {
    label: 'Straße',
    tooltip: {
      title: 'strasse [0…1]',
      content: 'Straßenname ohne Hausnummer.',
    },
  },
  houseNumber: {
    label: 'Hausnummer',
    tooltip: {
      title: 'hausnummer [0…1]',
      content: 'Hausnummer der Anschrift.',
    },
  },
  mailbox: {
    label: 'Postfach',
    tooltip: {
      title: 'postfach [0…1]',
      content: 'Bezeichnung/Nummer des Postfachs der Anschrift.',
    },
  },
  zipCode: {
    label: 'Postleitzahl',
    tooltip: {
      title: 'postleitzahl [1]',
      content: 'Postleitzahl der Anschrift.',
    },
  },
  location: {
    label: 'Ort',
    tooltip: {
      title: 'ort [1]',
      content: 'Ort als Freitext wie in Anschrift zu verwenden.',
    },
  },
  addition: {
    label: 'Zusatz',
    tooltip: {
      title: 'zusatz [0…1]',
      content: (
        <div>
          Ein Anschriftenzusatz beinhaltet ggf. erforderliche weitere Präzisierungen zu einer Anschrift.<br />
          Beispiele:
          <ul>
            <li>Hinterhof, 3. Aufgang,</li>
            <li>Haus A,</li>
            <li>3. Stock,</li>
            <li>Appartement 25a,</li>
            <li>3. Stock - Appartement 25 a,</li>
            <li>325a,</li>
            <li>Raum 77,</li>
          </ul>
        </div>
      ),
    },
  },
  geoCoding: {
    label: 'Geokodierung',
    tooltip: {
      title: 'geokodierung [0…m]',
      content: (
        <div>
          Zugeordnete geografische Position der Anschrift.<br /><br />
          Siehe: <a href="http://schema.org/GeoCoordinates" target="blank">http://schema.org/GeoCoordinates</a>.
        </div>
      ),
    },
  },
  directionURL: {
    label: 'Anfahrt-URL',
    tooltip: {
      title: 'anfahrturl [0…n]',
      content: 'Zugeordnete URLs für eine Anfahrtsbeschreibung im Internet.',
    },
  },
  id: {
    label: 'ID',
    tooltip: {
      title: 'id [0…1]',
      content: 'Eindeutige ID der Adresse',
    },
  },
  locationID: {
    label: 'ID des Orts',
    tooltip: {
      title: 'ortID [0…n]',
      content: 'ID des Orts',
    },
  },
  administrativeCoding: {
    label: 'Verwaltungpolitische Kodierung',
    tooltip: {
      title: 'verwaltungspolitischeKodierung [0…n]',
      content: '',
    },
  },
  validFrom : {
    label: 'Gültigkeitsbeginn',
    tooltip: {
      title: 'gueltigkeit > beginn [0…n]',
      content: 'Beginn der Gültigkeit',
    },
  },
  validUntil : {
    label: 'Gültigkeitsende',
    tooltip: {
      title: 'gueltigkeit > ende [0…n]',
      content: 'Ende der Gültigkeit',
    },
  },
};

class AddressComponent extends React.Component<AddressProps, AddressState> {
  private descriptionProps(key: string): any {
    const details: TooltipDetails = AddressPropDetails[key as keyof AddressPropDetailsType];

    if (details === undefined) {
      return {};
    }

    let props: any = {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };

    if (this.props.searchTerm === '') {
      return props;
    }

    const value = this.props.address[key as keyof Address];
    const valueMatching = JSON.stringify(value).toLowerCase().includes(this.props.searchTerm.toLowerCase());
    const labelMatching = details.label.toLowerCase().includes(this.props.searchTerm.toLowerCase());

    if (!labelMatching && !valueMatching) {
      props = {
        ...props,
        style: {
          display: 'none',
        },
      };
    }

    return props;
  }

  public render(): ReactNode {
    return (
      <div className="address">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('zipCode')}>
            <PrettyValueOrDefault value={this.props.address.zipCode} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('location')}>
            <PrettyValueOrDefault value={this.props.address.location} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('type')}>
            <PrettyValueOrDefault value={this.props.address.type} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('street')}>
            <PrettyValueOrDefault value={this.props.address.street} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('houseNumber')}>
            <PrettyValueOrDefault value={this.props.address.houseNumber} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('mailbox')}>
            <PrettyValueOrDefault value={this.props.address.mailbox} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('locationID')}>
            <PrettyValueOrDefault value={this.props.address.locationID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('administrativeCoding')}>
            <PrettyValueOrDefault value={this.props.address.administrativeCoding} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('addition')}>
            <PrettyValueOrDefault value={this.props.address.addition} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('geoCoding')}>
            <PrettyValueOrDefault value={this.props.address.geoCoding} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('directionURL')}>
            <PrettyValueOrDefault value={this.props.address.directionURL} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('id')}>
            <PrettyValueOrDefault value={this.props.address.id} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validFrom')}>
            <PrettyValueOrDefault value={this.props.address.validFrom} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validUntil')}>
            <PrettyValueOrDefault value={this.props.address.validUntil} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default AddressComponent;
