import '../Service.css';
import './index.css';

import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Input } from 'antd';
import debounce from 'lodash.debounce';
import React from 'react';

import { Competence, OnlineService, OrgUnit, Service } from '../../services/validator/ZuFi';
import CompetenceComponent from '../Competence';
import OnlineServiceComponent from '../OnlineService';
import OrgUnitComponent from '../OrgUnit';
import ServiceComponent from '../Service';

const { Panel } = Collapse;
const { Search } = Input;

type XZuFiResultPrettyProps = {
  services: Service[];
  organizationalUnits: OrgUnit[];
  onlineServices: OnlineService[];
  competences: Competence[];
}

type XZuFiResultPrettyState = {
  activeServiceKeys: string[];
  activeOrgUnitKeys: string[];
  activeOnlineServiceKeys: string[];
  filteredCompetences: Competence[];
  filteredServices: Service[];
  filteredOrgUnits: OrgUnit[];
  filteredOnlineServices: OnlineService[];
  searchTerm: string;
}

class XZuFiResultPretty extends React.Component<XZuFiResultPrettyProps, XZuFiResultPrettyState> {
  public constructor(props: XZuFiResultPrettyProps) {
    super(props);

    this.state = {
      activeServiceKeys: [],
      activeOrgUnitKeys: [],
      activeOnlineServiceKeys: [],
      filteredCompetences: props.competences,
      filteredServices: props.services,
      filteredOrgUnits: props.organizationalUnits,
      filteredOnlineServices: props.onlineServices,
      searchTerm: '',
    };

    this.setState = this.setState.bind(this);
  }

  public componentDidUpdate(oldProps: XZuFiResultPrettyProps) {
    const competencesChanged = oldProps.competences !== this.props.competences;
    const onlineServicesChanged = oldProps.onlineServices !== this.props.onlineServices;
    const orgUnitsChanged = oldProps.organizationalUnits !== this.props.organizationalUnits;
    const servicesChanged = oldProps.services !== this.props.services;

    if (competencesChanged || onlineServicesChanged || orgUnitsChanged || servicesChanged) {
      this.onSearchChange(this.state.searchTerm);
    }

  }

  private openService(svcKey: string) {
    this.setState({
      activeServiceKeys: [svcKey],
    });
  }

  private onServiceChange(svcKey: string[] | string) {
    let activeKeys = svcKey;
    if (!Array.isArray(svcKey)) {
      activeKeys = [svcKey];
    }

    this.setState({
      activeServiceKeys: activeKeys as string[],
    });
  }

  private openOrgUnit(ouKey: string) {
    this.setState({
      activeOrgUnitKeys: [ouKey],
    });
  }

  private onOrgUnitChange(ouKey: string[] | string) {
    let activeKeys = ouKey;
    if (!Array.isArray(ouKey)) {
      activeKeys = [ouKey];
    }

    this.setState({
      activeOrgUnitKeys: activeKeys as string[],
    });
  }

  private openOnlineService(svcKey: string) {
    this.setState({
      activeOnlineServiceKeys: [svcKey],
    });
  }

  private onOnlineServiceChange(svcKey: string[] | string) {
    let activeKeys = svcKey;
    if (!Array.isArray(svcKey)) {
      activeKeys = [svcKey];
    }

    this.setState({
      activeOnlineServiceKeys: activeKeys as string[],
    });
  }

  private onSearchChange(searchTerm: string) {
    if (searchTerm === '') {
      this.setState({
        activeServiceKeys: [],
        activeOrgUnitKeys: [],
        activeOnlineServiceKeys: [],
        filteredCompetences: this.props.competences,
        filteredServices: this.props.services,
        filteredOrgUnits: this.props.organizationalUnits,
        filteredOnlineServices: this.props.onlineServices,
        searchTerm,
      });

      return;
    }

    const filteredCompetences = this.props.competences.filter((competence: Competence) => {
      return JSON.stringify(competence).toLowerCase().includes(searchTerm.toLowerCase());
    });
    const filteredServices = this.props.services.filter((service: Service) => {
      return JSON.stringify(service).toLowerCase().includes(searchTerm.toLowerCase());
    });
    const filteredOrgUnits = this.props.organizationalUnits.filter((orgUnit: OrgUnit) => {
      return JSON.stringify(orgUnit).toLowerCase().includes(searchTerm.toLowerCase());
    });
    const filteredOnlineServices = this.props.onlineServices.filter((onlineService: OnlineService) => {
      return JSON.stringify(onlineService).toLowerCase().includes(searchTerm.toLowerCase());
    });

    const activeServiceKeys = filteredServices.map((svc: Service) => `svc-${svc.id}`);
    const activeOrgUnitKeys = filteredOrgUnits.map((ou: OrgUnit) => `ou-${ou.id}`);
    const activeOnlineServiceKeys = filteredOnlineServices.map((os: OnlineService) => `os-${os.id}`);

    this.setState({
      searchTerm,
      activeServiceKeys,
      activeOrgUnitKeys,
      activeOnlineServiceKeys,
      filteredCompetences,
      filteredServices,
      filteredOrgUnits,
      filteredOnlineServices,
    });
  }

  public render(): React.ReactNode {
    return (
      <div className="competence-properties">
        <div className="hint-toolbar">
          <Search
            className="pretty-toolbar-search" 
            allowClear
            placeholder="Suche..."
            onChange={(ev: any) => {
              debounce(() => {
                this.onSearchChange(ev.target.value)
              }, 300)();
            }}
          />
        </div>
        { this.state.filteredCompetences.length !== 0 && <div>
          <h1 className="competence-title">Zuständigkeiten</h1>
          <Collapse
            bordered={false}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            ghost
          >
            { this.state.filteredCompetences.map((competence: Competence, index: number) => (
              <Panel
                className="zufi-title"
                header={(
                  <h3 className="service-title">{
                    `Zuständigkeit ${index + 1}`
                  }</h3>
                )}
                key={`comp-${index}`}
              >
                <div className="ml-24">
                  <CompetenceComponent
                    services={this.props.services}
                    orgUnits={this.props.organizationalUnits}
                    onlineServices={this.props.onlineServices}
                    competence={competence}
                    key={index}
                    index={index}
                    openServiceCallback={this.openService.bind(this)}
                    openOrgUnitCallback={this.openOrgUnit.bind(this)}
                    openOnlineServiceCallback={this.openOnlineService.bind(this)}
                  />
                </div>
              </Panel>
            )) }
          </Collapse>
        </div> }
        { this.state.filteredServices.length !== 0 && <div>
          <h1 className="competence-title">Leistungen</h1>
          <Collapse
            activeKey={this.state.activeServiceKeys}
            onChange={(key: string[] | string) => {
              this.onServiceChange(key);
            }}
            bordered={false}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            ghost
          >
            { this.state.filteredServices.map((service: Service, index: number) => (
              <Panel
                className="zufi-title"
                header={(
                  <h3 className="service-title">{
                    service.name
                      ? `${service.name} (Leistung ${index + 1})`
                      : `Leistung ${index + 1}`
                  }</h3>
                )}
                id={`svc-${service.id}`}
                key={`svc-${service.id}`}
              >
                <div className="ml-24">
                  <ServiceComponent service={service} key={index} index={index} searchTerm={this.state.searchTerm} />
                </div>
              </Panel>
            )) }
          </Collapse>
        </div> }
        { this.state.filteredOrgUnits.length !== 0 && <div>
          <h1 className="competence-title">Organisationseinheiten</h1>
          <Collapse
            activeKey={this.state.activeOrgUnitKeys}
            onChange={(key: string[] | string) => {
              this.onOrgUnitChange(key);
            }}
            bordered={false}
            defaultActiveKey={['1']}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            ghost
          >
            { this.state.filteredOrgUnits.map((orgUnit: OrgUnit, index: number) => (
              <Panel
                className="zufi-title"
                header={(
                  <h3 className="service-title">{
                    orgUnit.name
                      ? `${orgUnit.name} (Organisationseinheit ${index + 1})`
                      : `Organisationseinheit ${index + 1}`
                  }</h3>
                )}
                key={`ou-${orgUnit.id}`}
                id={`ou-${orgUnit.id}`}
              >
                <div className="ml-24">
                  <OrgUnitComponent orgUnit={orgUnit} key={index} index={index} searchTerm={this.state.searchTerm} />
                </div>
              </Panel>
            )) }
          </Collapse>
        </div> }
        { this.state.filteredOnlineServices.length !== 0 && <div>
          <h1 className="competence-title">Onlinedienste</h1>
          <Collapse
            activeKey={this.state.activeOnlineServiceKeys}
            onChange={(key: string[] | string) => {
              this.onOnlineServiceChange(key);
            }}
            bordered={false}
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
            ghost
          >
            { this.state.filteredOnlineServices.map((onlineService: OnlineService, index: number) => (
              <Panel
                className="zufi-title"
                header={(
                  <h3 className="service-title">{
                    onlineService.url
                      ? `${onlineService.url} (Onlinedienst ${index + 1})`
                      : `Onlinedienst ${index + 1}`
                  }</h3>
                )}
                key={`os-${onlineService.id}`}
                id={`os-${onlineService.id}`}
              >
                <div className="ml-24">
                  <OnlineServiceComponent onlineService={onlineService} key={index} index={index} />
                </div>
              </Panel>
            )) }
          </Collapse>
        </div>}
      </div>
    );
  }
}

export default XZuFiResultPretty;
