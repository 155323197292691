import { rules, RulesAPIResponse } from '../../../services/validator';
import * as actions from './actions';

export function loadRules(): Function {
  return function(dispatch: Function, _getState: Function) {
    dispatch(actions.rulesLoading());

    rules()
      .then((result: RulesAPIResponse) => {
        dispatch(actions.rulesSuccess(result));
      })
      .catch((reason: any) => {
        dispatch(actions.rulesFailure(reason.message as string));
      });
  }
}

export * from './actions';
export * from './reducer';
export * from './selectors';
