import './Service.css';

import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Collapse, Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { Service } from '../services/validator/ZuFi';
import AuthorComponent from './Author';
import CategoryComponent from './Category';
import CostsComponent from './Costs';
import DeadlineComponent from './Deadline';
import DefaultValue from './DefaultValue';
import DocumentModuleComponent from './DocumentModule';
import PrettyValueOrDefault from './PrettyValueOrDefault';
import ProcessingTimeComponent from './ProcessingTime';
import TextCustomComponent from './TextCustom';

const { Panel } = Collapse;

type ServiceComponentProps = {
  service: Service;
  key: number;
  index: number;
  searchTerm: string;
}

type ServiceComponentState = {
  allProperties: boolean;
}

type ServicePropDetailsType = {
  id: TooltipDetails;
  leiKa: TooltipDetails;
  name: TooltipDetails;
  name2: TooltipDetails;
  synonyms: TooltipDetails;
  shortText: TooltipDetails;
  longText: TooltipDetails;
  legalBasis: TooltipDetails;
  requiredDocuments: TooltipDetails;
  prerequisites: TooltipDetails;
  costs: TooltipDetails;
  procedure: TooltipDetails;
  processingTime: TooltipDetails;
  deadline: TooltipDetails;
  deadlineDescription: TooltipDetails;
  deadlineURL: TooltipDetails;
  approvedAt: TooltipDetails;
  approvedBy: TooltipDetails;
  furtherInformation: TooltipDetails;
  notes: TooltipDetails;
  teaser: TooltipDetails;
  informationNotes: TooltipDetails;
  document: TooltipDetails;
  originPortal: TooltipDetails;
  type: TooltipDetails;
  sdg: TooltipDetails;
  addressee: TooltipDetails;
  category: TooltipDetails;
  idServiceInContext: TooltipDetails;
  idProcess: TooltipDetails;
  modifiedAt: TooltipDetails;
  valuationArea: TooltipDetails;
  textCustom: TooltipDetails;
  relevantForIndustry: TooltipDetails;
  relevantForLegalForm: TooltipDetails;
  relevantForNationality: TooltipDetails;
  validFrom: TooltipDetails;
  validUntil: TooltipDetails;
  author: TooltipDetails;
  language: TooltipDetails;
  languageCode: TooltipDetails;
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const ServicePropDetails: ServicePropDetailsType = {
  id: {
    label: 'ID',
    tooltip: {
      title: 'id [1]',
      content: 'Eindeutige ID der Leistung',
    },
  },
  leiKa: {
    label: 'Schlüssel',
    tooltip: {
      title: 'schluessel [1…n]',
      content: 'LeiKa-Schlüssel',
    },
  },
  name: {
    label: 'Leistungsbezeichnung',
    tooltip: {
      title: 'leistungsbezeichnung [0…1]',
      content: 'Leistungsbezeichnung',
    },
  },
  name2: {
    label: 'Leistungsbezeichnung 2',
    tooltip: {
      title: 'leistungsbezeichnungs2 [0…1]',
      content: 'Leistungsbezeichnung II (Leistungsname)',
    },
  },
  synonyms: {
    label: 'Begriffe im Kontext',
    tooltip: {
      title: 'begriffeImKontext [0…1]',
      content: 'Begriffe im Kontext',
    },
  },
  shortText: {
    label: 'Kurztext',
    tooltip: {
      title: 'kurztext [0…1]',
      content: 'Kurztext',
    },
  },
  longText: {
    label: 'Volltext',
    tooltip: {
      title: 'volltext [0…1]',
      content: 'Volltext',
    },
  },
  legalBasis: {
    label: 'Rechtsgrundlage',
    tooltip: {
      title: 'rechtsgrundlage [0…1]',
      content: 'Rechtsgrundlage(n)',
    },
  },
  requiredDocuments: {
    label: 'Erforderliche Unterlagen',
    tooltip: {
      title: 'erforderlicheUnterlagen [0…1]',
      content: 'Erforderliche Unterlagen',
    },
  },
  prerequisites: {
    label: 'Voraussetzungen',
    tooltip: {
      title: 'voraussetzungen [0…1]',
      content: 'Voraussetzungen',
    },
  },
  costs: {
    label: 'Kosten',
    tooltip: {
      title: 'kosten [0…1]',
      content: 'Kosten (Gebühren, Auslagen etc.)',
    },
  },
  procedure: {
    label: 'Verfahrensablauf',
    tooltip: {
      title: 'verfahrensablauf [0…1]',
      content: 'Verfahrensablauf',
    },
  },
  processingTime: {
    label: 'Bearbeitungsdauer',
    tooltip: {
      title: 'bearbeitsungdauer [0…1]',
      content: 'Bearbeitungsdauer',
    },
  },
  deadline: {
    label: 'Fristen',
    tooltip: {
      title: 'modulFrist [0…1]',
      content: 'Fristen',
    },
  },
  deadlineDescription: {
    label: 'Fristen Beschreibung',
    tooltip: {
      title: 'modulFrist > beschreibung [0…1]',
      content: 'Fristen Beschreibung',
    },
  },
  deadlineURL: {
    label: 'Fristen weiterführender Link',
    tooltip: {
      title: 'modulFrist > weiterfuehrenderLink [0…1]',
      content: 'Fristen Link',
    },
  },
  approvedAt: {
    label: 'Fachlich freigegeben am',
    tooltip: {
      title: 'modulFachlicheFreigabe > fachlichFreigegebenAm [0…1]',
      content: 'Fachliche Freigabe am',
    },
  },
  approvedBy: {
    label: 'Fachlich freigegeben durch',
    tooltip: {
      title: 'modulFachlicheFreigabe > fachlichFregegebenDurch [0…n]',
      content: 'Fachliche Freigabe durch',
    },
  },
  furtherInformation: {
    label: 'weiterfuehrende Informationen',
    tooltip: {
      title: 'weiterfuehrendeInformationen [0…1]',
      content: 'Weiterführende Informationen',
    },
  },
  notes: {
    label: 'Hinweise',
    tooltip: {
      title: 'hinweise [0…1]',
      content: 'Hinweise (Besonderheiten)',
    },
  },
  teaser: {
    label: 'Teaser',
    tooltip: {
      title: 'teaser [0…1]',
      content: 'Teaser Text für die Verwaltungssuchmaschine',
    },
  },
  informationNotes: {
    label: 'Auskunftshinweis',
    tooltip: {
      title: 'modulAuskunftshinweis [0…1]',
      content: 'Angaben für Servicecenter-Agenten zur Leistung. Achtung: Diese Angaben dürfen öffentlich nicht dargestellt werden.',
    },
  },
  document: {
    label: 'Dokumente',
    tooltip: {
      title: 'modulDokument [0…1]',
      content: 'Im Verfahrensablauf der Leistung beizubringende oder hervorgehende Dokumente.',
    },
  },
  originPortal: {
    label: 'Ursprungsportal',
    tooltip: {
      title: 'modulUrsprungsportal [0…*]',
      content: 'Information zur Ansicht der Leistung im Ursprungsportal bzw. -system.',
    },
  },
  type: {
    label: 'Typ',
    tooltip: {
      title: 'typisierung [0…1]',
      content: 'Typisierung der Leistung',
    },
  },
  sdg: {
    label: 'Informationen SDG',
    tooltip: {
      title: 'informationsbereichSDG [0…*]',
      content: 'Zuordnung der SDG Informationsbereiche, die für Bürger und Unternehmen relevant sind, die ihre Binnenmarkt- rechte ausüben.',
    },
  },
  addressee: {
    label: 'Adressat',
    tooltip: {
      title: 'leistungsadressat [0…n]',
      content: 'Adressat der Leistung',
    },
  },
  category: {
    label: 'Kategorie',
    tooltip: {
      title: 'kategorie [0…n]',
      content: 'Kategorie der Leistung',
    },
  },
  idServiceInContext: {
    label: 'ID Leistung im Kontext',
    tooltip: {
      title: 'idLeistungImKontext [0…n]',
      content: 'ID der Leistung im Kontext',
    },
  },
  idProcess: {
    label: 'Prozess ID',
    tooltip: {
      title: 'idProzess [0…n]',
      content: 'ID des Prozesses',
    },
  },
  modifiedAt: {
    label: 'Zuletzt geändert am',
    tooltip: {
      title: 'versionsinformation > geaendertDatumZeit [0…1]',
      content: 'Datum und Uhrzeit der letzten Änderung',
    },
  },
  valuationArea: {
    label: 'Gültigkeitsgebiet',
    tooltip: {
      title: 'gueltigkeitGebietID [0…n]',
      content: 'Gebiets-IDs in denen die Leistung gültig ist.',
    },
  },
  textCustom: {
    label: 'Individuelles Textmodul',
    tooltip: {
      title: 'modulTextIndividuell [0…n]',
      content: 'Individuelle Textmodule',
    },
  },
  relevantForIndustry: {
    label: 'Relevanz in Wirtschaftszweigen',
    tooltip: {
      title: 'relevantFuerWirtschaftszweig > code [0…n]',
      content: 'Code der Wirtschaftszweige',
    },
  },
  relevantForLegalForm: {
    label: 'Relevanz in Rechtsforment',
    tooltip: {
      title: 'relevantFuerRechtsform [0…n]',
      content: 'Code der Rechtsformen',
    },
  },
  relevantForNationality : {
    label: 'Relevanz für die Staatsangehörigkeit',
    tooltip: {
      title: 'relevantFuerStaatsangehörigkeit [0…n]',
      content: 'Informationen für die Relevanz in der Staatsangehörigkeit',
    },
  },
  validFrom : {
    label: 'Gültigkeitsbeginn',
    tooltip: {
      title: 'gueltigkeit > beginn [0…n]',
      content: 'Beginn der Gültigkeit',
    },
  },
  validUntil : {
    label: 'Gültigkeitsende',
    tooltip: {
      title: 'gueltigkeit > ende [0…n]',
      content: 'Ende der Gültigkeit',
    },
  },
  author : {
    label: 'Herausgeber',
    tooltip: {
      title: 'herausgeber [0…1]',
      content: 'Der Herausgeber',
    },
  },
  language : {
    label: 'Sprachbezeichnung (Deutsch)',
    tooltip: {
      title: 'sprachversion > sprachbezeichnungDeutsch [1]',
      content: 'Bezeichnung der Sprach in Deutsch, z.B. "Chinesisch"',
    },
  },
  languageCode : {
    label: 'Code der Sprache',
    tooltip: {
      title: 'sprachversion > languageCode [1]',
      content: (
        <div>
          Code der Sprache der Sprachversion. Abgebildet mit W3C-Datentyp language (https://www.w3.org/TR/xmlsche-ma-2/#language). Zum Beispiel "en", "en-US", "de", "de-DE" oder "zh-cn".
        </div>
      ),
    },
  },
};

class ServiceComponent extends React.Component<ServiceComponentProps, ServiceComponentState> {
  public constructor(props: ServiceComponentProps) {
    super(props);

    this.state = {
      allProperties: false,
    };

    this.setAllProperties = this.setAllProperties.bind(this);
  }

  public componentDidUpdate(prevProps: ServiceComponentProps) {
    if (prevProps.searchTerm.length !== 0 && this.props.searchTerm.length === 0 && this.state.allProperties) {
      this.setState({
        allProperties: false,
      });
    }

    if (this.props.searchTerm.length !== 0 && !this.state.allProperties) {
      this.setState({
        allProperties: true,
      });
    }
  }

  private descriptionProps(key: string): any {
    const details: TooltipDetails = ServicePropDetails[key as keyof ServicePropDetailsType];

    if (details === undefined) {
      return {};
    }

    let props: any = {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };

    if (this.props.searchTerm === '') {
      return props;
    }

    const value = this.props.service[key as keyof Service];
    const labelMatching = details.label.toLowerCase().includes(this.props.searchTerm.toLowerCase());
    const valueMatching = JSON.stringify(value).toLowerCase().includes(this.props.searchTerm.toLowerCase());

    if (!labelMatching && !valueMatching) {
      props = {
        ...props,
        style: {
          display: 'none',
        },
      };
    }

    return props;
  }

  private setAllProperties(expanded: boolean) {
    this.setState({
      allProperties: expanded,
    });
  }

  public render(): React.ReactNode {
    return (
      <div className="leistung">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('id')}>
            <PrettyValueOrDefault value={this.props.service.id} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('leiKa')}>
            <PrettyValueOrDefault value={this.props.service.leiKa} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('name')}>
            <PrettyValueOrDefault value={this.props.service.name} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('name2')}>
            <PrettyValueOrDefault value={this.props.service.name2} />
          </Descriptions.Item>
          <Descriptions.Item className="multiline details-row" {...this.descriptionProps('synonyms')}>
            {this.props.service.synonyms.map((synonym: string, key: number) => (
              <span key={key}>{synonym}<br /></span>
            ))}
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('shortText')}>
            <PrettyValueOrDefault value={this.props.service.shortText} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('longText')}>
            <PrettyValueOrDefault value={this.props.service.longText} />
          </Descriptions.Item>
        </Descriptions>
        {!this.state.allProperties && <Button
          type="primary"
          ghost
          onClick={() => {
            this.setAllProperties(true);
          }}
        >
          Alle Felder der Leistung anzeigen
        </Button>}
        <Descriptions
          column={1}
          style={{
            transition: '.5s ease',
            transitionProperty: 'all',
          }}
          className={this.state.allProperties ? '' : 'hidden'}
        >
          <Descriptions.Item className="details-row" {...this.descriptionProps('legalBasis')}>
            <PrettyValueOrDefault value={this.props.service.legalBasis} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('requiredDocuments')}>
            <PrettyValueOrDefault value={this.props.service.requiredDocuments} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('prerequisites')}>
            <PrettyValueOrDefault value={this.props.service.prerequisites} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('procedure')}>
            <PrettyValueOrDefault value={this.props.service.procedure} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('processingTime')}>
            { typeof this.props.service.processingTime === 'string'
              ? <DefaultValue />
              : <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                ghost
              >
                <Panel
                  header={(
                    <span>{
                      this.props.service.processingTime.description
                        ? `${this.props.service.processingTime.description} (Bearbeitsungdauer)`
                        : 'Bearbeitsungdauer'
                    }</span>
                  )}
                  key={`processingTime-${this.props.service.id}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <ProcessingTimeComponent processingTime={this.props.service.processingTime} />
                  </div>
                </Panel>
              </Collapse>
            }
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('deadline')}>
            { typeof this.props.service.deadline === 'string'
              ? <DefaultValue />
              : <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                ghost
              >
                <Panel
                  header={(
                    <span>{
                      this.props.service.deadline.description
                        ? `${this.props.service.deadline.description} (Frist)`
                        : 'Frist'
                    }</span>
                  )}
                  key={`deadline-${this.props.service.id}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <DeadlineComponent deadline={this.props.service.deadline} />
                  </div>
                </Panel>
              </Collapse>
            }
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('deadlineDescription')}>
            <PrettyValueOrDefault value={this.props.service.deadlineDescription} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('deadlineURL')}>
            <PrettyValueOrDefault value={this.props.service.deadlineURL} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('approvedAt')}>
            <PrettyValueOrDefault value={this.props.service.approvedAt} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('approvedBy')}>
            <PrettyValueOrDefault value={this.props.service.approvedBy} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('furtherInformation')}>
            <PrettyValueOrDefault value={this.props.service.furtherInformation} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('notes')}>
            <PrettyValueOrDefault value={this.props.service.notes} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('teaser')}>
            <PrettyValueOrDefault value={this.props.service.teaser} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('costs')}>
            { typeof this.props.service.costs === 'string'
              ? <DefaultValue />
              : <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                ghost
              >
                <Panel
                  header={(
                    <span>{
                      this.props.service.costs.description
                        ? `${this.props.service.costs.description} (Kosten)`
                        : 'Kosten'
                    }</span>
                  )}
                  key={`costs-${this.props.service.id}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <CostsComponent costs={this.props.service.costs} />
                  </div>
                </Panel>
              </Collapse>
            }
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('informationNotes')}>
            <PrettyValueOrDefault value={this.props.service.informationNotes} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('document')}>
            { typeof this.props.service.document === 'string'
              ? <DefaultValue />
              : <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                ghost
              >
                <Panel
                  header={(
                    <span>{
                      this.props.service.document.description
                        ? `${this.props.service.document.description} (Dokument)`
                        : 'Dokument'
                    }</span>
                  )}
                  key={`document-${this.props.service.id}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <DocumentModuleComponent documentModule={this.props.service.document} />
                  </div>
                </Panel>
              </Collapse>
            }
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('originPortal')}>
            <PrettyValueOrDefault value={this.props.service.originPortal} />
          </Descriptions.Item>
          <Descriptions.Item className="multiline details-row" {...this.descriptionProps('type')}>
            {this.props.service.type.map((type: string, key: number) => (
              <span key={key}>{key < this.props.service.type.length - 1 ? `${type}, ` : type}</span>
            ))}
            {this.props.service.type.length === 0 && <DefaultValue />}
          </Descriptions.Item>
          <Descriptions.Item  className="multiline details-row"{...this.descriptionProps('sdg')}>
            {this.props.service.sdg.map((code: string, key: number) => (
              <span key={key}>{key < this.props.service.sdg.length - 1 ? `${code}, ` : code}</span>
            ))}
            {this.props.service.sdg.length === 0 && <DefaultValue />}
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('addressee')}>
            <PrettyValueOrDefault value={this.props.service.addressee} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('category')}>
            { typeof this.props.service.category === 'string'
              ? <DefaultValue />
              : <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                ghost
              >
                <Panel
                  header={(
                    <span>{
                      this.props.service.category.description
                        ? `${this.props.service.category.description} (Kategorie)`
                        : 'Kategorie'
                    }</span>
                  )}
                  key={`category-${this.props.service.id}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <CategoryComponent category={this.props.service.category} />
                  </div>
                </Panel>
              </Collapse>
            }
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('idServiceInContext')}>
            <PrettyValueOrDefault value={this.props.service.idServiceInContext} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('idProcess')}>
            <PrettyValueOrDefault value={this.props.service.idProcess} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('modifiedAt')}>
            <PrettyValueOrDefault value={this.props.service.modifiedAt} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('valuationArea')}>
            <PrettyValueOrDefault value={this.props.service.valuationArea} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('textCustom')}>
            { typeof this.props.service.textCustom === 'string'
              ? <DefaultValue />
              : <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                ghost
              >
                <Panel
                  header={(
                    <span>{
                      this.props.service.textCustom.name
                        ? `${this.props.service.textCustom.name} (Textmodul individuell)`
                        : 'Textmodul individuell'
                    }</span>
                  )}
                  key={`textCustom-${this.props.service.id}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <TextCustomComponent textCustom={this.props.service.textCustom} />
                  </div>
                </Panel>
              </Collapse>
            }
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('relevantForIndustry')}>
            <PrettyValueOrDefault value={this.props.service.relevantForIndustry} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('relevantForLegalForm')}>
            <PrettyValueOrDefault value={this.props.service.relevantForLegalForm} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('relevantForNationality')}>
            <PrettyValueOrDefault value={this.props.service.relevantForNationality} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validFrom')}>
            <PrettyValueOrDefault value={this.props.service.validFrom} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validUntil')}>
            <PrettyValueOrDefault value={this.props.service.validUntil} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('author')}>
            { typeof this.props.service.author === 'string'
              ? <DefaultValue />
              : <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                ghost
              >
                <Panel
                  header={(
                    <span>{
                      this.props.service.author.name
                        ? `${this.props.service.author.name} (Herausgeber)`
                        : 'Herausgeber'
                    }</span>
                  )}
                  key={`author-${this.props.service.id}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <AuthorComponent author={this.props.service.author} />
                  </div>
                </Panel>
              </Collapse>
            }
          </Descriptions.Item>
        </Descriptions>
        {this.state.allProperties && <
          Button
          onClick={() => {
            this.setAllProperties(false);
          }}
        >
          Weniger Felder anzeigen
        </Button>}
      </div>
    );
  }
}

export default ServiceComponent;
