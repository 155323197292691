import { Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { Category } from '../services/validator/ZuFi';
import PrettyValueOrDefault from './PrettyValueOrDefault';


type CategoryProps = {
  category: Category;
};

type CategoryState = {

};

type CategoryPropDetailsType = {
  classID: TooltipDetails,
  className: TooltipDetails,
  id: TooltipDetails,
  name: TooltipDetails,
  classSecondaryID: TooltipDetails,
  classDescription: TooltipDetails,
  classVersion: TooltipDetails,
  secondaryID: TooltipDetails,
  parentClassCategoryID: TooltipDetails,
  childClassCategoryID: TooltipDetails,
  description: TooltipDetails,
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const CategoryPropDetails: CategoryPropDetailsType = {
  classID: {
    label: 'ID der Kategorieklasse',
    tooltip: {
      title: 'klasse > id',
      content: undefined,
    },
  },
  className: {
    label: 'Bezeichnung der Kategorieklasse',
    tooltip: {
      title: 'klasse > bezeichnung',
      content: undefined,
    },
  },
  id: {
    label: 'ID der Kategorie',
    tooltip: {
      title: 'id',
      content: undefined,
    },
  },
  name: {
    label: 'Bezeichnung',
    tooltip: {
      title: 'bezeichnung',
      content: undefined,
    },
  },
  classSecondaryID: {
    label: 'Sekundäre ID der Klasse',
    tooltip: {
      title: 'klasse > idSekundaer',
      content: undefined,
    },
  },
  classDescription: {
    label: 'Beschreibung der Klasse',
    tooltip: {
      title: 'klasse > beschreibung',
      content: undefined,
    },
  },
  classVersion: {
    label: 'Version der Klasse',
    tooltip: {
      title: 'klasse > version',
      content: undefined,
    },
  },
  secondaryID: {
    label: 'Sekundäre ID',
    tooltip: {
      title: 'idSekundaer',
      content: undefined,
    },
  },
  parentClassCategoryID: {
    label: 'ID der übergeordneten Kategorie',
    tooltip: {
      title: 'uebergeordneteKategorieID',
      content: undefined,
    },
  },
  childClassCategoryID: {
    label: 'ID der untegeordneten Kategorie',
    tooltip: {
      title: 'untergeordneteKategorieID',
      content: undefined,
    },
  },
  description: {
    label: 'Beschreibung',
    tooltip: {
      title: 'beschreibung',
      content: undefined,
    },
  },
};

class CategoryComponent extends React.Component<CategoryProps, CategoryState> {
  private descriptionProps(key: string): any {
    const details: TooltipDetails = CategoryPropDetails[key as keyof CategoryPropDetailsType];

    if (details === undefined) {
      return {};
    }

    return {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };
  }

  public render(): React.ReactNode {
    return (
      <div className="leistung">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('classID')}>
            <PrettyValueOrDefault value={this.props.category.classID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('className')}>
            <PrettyValueOrDefault value={this.props.category.className} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('id')}>
            <PrettyValueOrDefault value={this.props.category.id} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('name')}>
            <PrettyValueOrDefault value={this.props.category.name} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('classSecondaryID')}>
            <PrettyValueOrDefault value={this.props.category.classSecondaryID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('classDescription')}>
            <PrettyValueOrDefault value={this.props.category.classDescription} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('classVersion')}>
            <PrettyValueOrDefault value={this.props.category.classVersion} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('secondaryID')}>
            <PrettyValueOrDefault value={this.props.category.secondaryID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('parentClassCategoryID')}>
            <PrettyValueOrDefault value={this.props.category.parentClassCategoryID} />
          </Descriptions.Item>
          <Descriptions.Item className="multiline details-row" {...this.descriptionProps('childClassCategoryID')}>
            {this.props.category.childClassCategoryID.map((childCategoryID: string, key: number) => (
              <span key={key}>{childCategoryID}<br /></span>
            ))}
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('description')}>
            <PrettyValueOrDefault value={this.props.category.description} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default CategoryComponent;
