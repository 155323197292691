import { validate, ValidatorAPIResponse } from '../../../services/validator';
import * as actions from './actions';
import { getFormat } from './selectors';

export function parseXML(xml: string): Function {
  return function(dispatch: Function, getState: Function) {
    dispatch(actions.loading());

    dispatch(actions.changeXML(xml));

    validate(xml, getFormat(getState()))
      .then((result: ValidatorAPIResponse) => {
        dispatch(actions.validatorResult(result));
      })
      .catch((reason: any) => {
        dispatch(actions.validatorFailure(reason.message as string));
      });
  }
}

export * from './actions';
export * from './reducer';
export * from './selectors';
