import './index.css';

import { CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Collapse, List } from 'antd';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';

import { RulesAPIResponse } from '../../services/validator';
import { getRules, loadRules } from './duck';

const { Panel } = Collapse;

type RuleSetProps = {
  rules: RulesAPIResponse;
  dispatch: Function;
};

type RuleSetState = {

};

const readbleNames: Record<string, string> = {
  competences: 'Zuständigkeiten',
  services: 'Leistungen',
  orgUnits: 'Organisationseinheiten',
  onlineServices: 'Onlinedienste',
};

class RuleSet extends React.Component<RuleSetProps, RuleSetState> {
  public componentDidMount() {
    this.props.dispatch(loadRules());
  }

  private renderItem(item: any, index: number): ReactElement {
    let icon = <span className="error-icon"><CloseCircleFilled /></span>;

    if (item.level !== 'error') {
      icon = <span className="warning-icon"><ExclamationCircleFilled /></span>;
    }

    return (
      <List.Item>
        <List.Item.Meta
          key={index}
          avatar={icon}
          title={item.title}
        />
      </List.Item>
    );
  }

  private renderRules(): ReactElement[] {
    const output: ReactElement[] = [];
    let key = 1;
    for (const validator in this.props.rules) {
      output.push(<Panel
        key={key}
        header={<b>{readbleNames[validator]}</b>}
      >
        <List
          className="rule-list"
          itemLayout="horizontal"
          dataSource={this.props.rules[validator as keyof RulesAPIResponse]}
          renderItem={(item: any, index: number) => this.renderItem(item, index)}
        />
      </Panel>);
      key += 1;
    }

    return output;
  }

  public render(): React.ReactNode {
    return (
      <Collapse ghost>
        {this.renderRules()}
      </Collapse>
    );
  }
}

export default connect((state: any) => ({
  rules: getRules(state),
}))(RuleSet);
