import './Imprint.css';

import { Layout } from 'antd';
import React from 'react';


const { Content } = Layout;


type ImprintLayoutProps = {

};

type ImprintLayoutState = {

}

class ImprintLayout extends React.Component<ImprintLayoutProps, ImprintLayoutState> {
  public render() {
    return (
      <Layout className="layout-content">
        <Content className="imprint-content">
          <h1>Impressum</h1>
          <h5><strong>Herausgeber</strong></h5>
          <p>
            Land Nordrhein-Westfalen<br/>
            Ministerium für Heimat, Kommunales, Bau und Digitalisierung des Landes Nordrhein-Westfalen<br/>
            Jürgensplatz 1, 40219 Düsseldorf<br/>
            Telefon: 0211-8618-50<br/>
            E-Mail: <a href="mailto:poststelle@mhkbd.nrw.de">poststelle@mhkbd.nrw.de</a>
          </p>

          <h5><strong>Rechtsform</strong></h5>

          <p>
            Das Land Nordrhein-Westfalen ist eine Körperschaft des Öffentlichen Rechts.
          </p>

          <h5><strong>Vertretungsberechtigt</strong></h5>

          <p>
            Das Ministerium wird vertreten durch Frau Ministerin Ina Scharrenbach MdL, in Rechts- und Verwaltungsangelegenheiten durch Herrn Staatssekretär Daniel Sieveke.
          </p>

          <h5><strong>Redaktionell verantwortlich</strong></h5>

          <p>
            Prof. Dr. Andreas Meyer-Falcke<br/>
            Beauftragter der Landesregierung für Informationstechnik (CIO)
          </p>

          <p>
            Ministerium für Heimat, Kommunales, Bau und Digitalisierung des Landes Nordrhein-Westfalen<br/>
            Jürgensplatz 1, 40219 Düsseldorf
          </p>

          <h5><strong>Ansprechpartner zu fachlichen Fragen</strong></h5>

          <p>
            Bei Fragen zur Landesredaktion NRW wenden Sie sich bitte an
          </p>

          <p>
            d-NRW AöR<br/>
            Rheinische Str. 1<br/>
            44137 Dortmund<br/>
            E-Mail: <a href="mailto:Landesredaktion@digitales.nrw.de">Landesredaktion@digitales.nrw.de</a>
          </p>

          <div className="my-5">
            <a href="http://www.d-nrw.de/" target="_blank">
              <img src="images/logo-d-nrw.png" alt="d-NRW Logo"/>
            </a>
          </div>

          <h5><strong>Technische Unterstützung</strong></h5>

          <p>
            d-NRW AöR, <a href="http://www.d-nrw.de/" target="_blank">http://www.d-nrw.de/</a><br/>
            in Zusammenarbeit mit<br/>
            publicplan GmbH, <a href="http://www.publicplan.de/" target="_blank">http://www.publicplan.de/</a>
          </p>

          <div className="my-5">
            <a href="http://www.publicplan.de/" target="_blank">
              <img src="images/publicplan_gmbh.png" alt="publicplan GmbH Logo"/>
            </a>
          </div>

          <h5><strong>Haftungsausschluss</strong></h5>

          <p>
            Von dieser Website wird auf zahlreiche Online-Angebote verwiesen.
            Die Inhalte der verlinkten Internetseiten sind unter anderem von Dritten gestaltet,
            auf die das Ministerium keinen Einfluss hat. Das Ministerium macht sich die
            dort aufgeführten Inhalte nicht zu eigen. Für die Angebote Dritter wird
            keinerlei Haftung übernommen. Sollten Sie auf den Internetseiten des Ministeriums
            oder auf solchen, auf die verwiesen wird, bedenkliche oder rechtswidrige Inhalte
            feststellen, ist das Ministerium für einen entsprechenden Hinweis dankbar.
          </p>

          <p>
            Zusätzlich weisen wir daraufhin, dass Teile der Internetseiten oder das
            gesamte Angebot ohne gesonderte Ankündigung verändert, ergänzt, oder
            gelöscht werden können. Auch die vorübergehende oder endgültige Beendigung
            der Veröffentlichung ist ohne Ankündigung möglich.
          </p>

          <h3>Datenschutzhinweis</h3>

          <p>
            Das Ministerium unterliegt als öffentliche Stelle den Bestimmungen
            des nordrhein-westfälischen Datenschutzgesetzes und nimmt den Schutz
            Ihrer personenbezogenen Daten sehr ernst. Nachfolgend möchten wir Ihnen
            einen Überblick geben, wie der Datenschutz konkret ausgestaltet ist.
          </p>

          <h5><strong>Automatische Speicherung von Daten</strong></h5>

          <p>
            Bei jedem Zugriff auf Inhalte der Internetseite werden vorübergehend
            Daten gespeichert, die möglicherweise eine Identifizierung zulassen.
            Die folgenden Daten werden bei jedem Aufruf einer Internetseite des
            Landesportals erhoben:
          </p>

          <ul>
            <li>Datum und Uhrzeit des Abrufs</li>
            <li>Abfrage, die der Client gestellt hat</li>
            <li>IP-Adresse des aufrufenden Rechners</li>
            <li>Herkunft des aufrufenden Rechners</li>
            <li>Verweildauer auf der Website</li>
            <li>Clientinformationen (Browser, Betriebssystem, eingestellte Auflösung)</li>
          </ul>

          <p>
            Zu Zwecken der statistischen Auswertung und der Nutzungsanalyse werden zu jeder Zeit nur anonymisierte IP-Adressen verwendet.
          </p>

          <h5><strong>Verwendung von eigenen Cookies</strong></h5>

          <p>
            Bei dem Aufruf bestimmter Seiten wird ein so genanntes Session-Cookie gesetzt.
            Hierbei handelt es sich um eine kleine Textdatei.
            Diese Datei dient ausschließlich dazu, bestimmte Applikationen nutzen zu können.
            Sie können bei den meisten Webbrowsern die Einstellungen so ändern, dass Ihr Browser
            neue Cookies nicht akzeptiert oder Sie können erhaltenen Cookies löschen lassen.
            Wie dies konkret für Ihren Browser funktioniert, kann in der Regel über dessen Hilfe-Funktion in Erfahrung gebracht werden.
          </p>
        </Content>
      </Layout>
    );
  }
}

export default ImprintLayout;
