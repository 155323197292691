import './index.css';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Alert, Input, Switch } from 'antd';
import React, { ReactElement } from 'react';

import { ValidationError, ValidationErrorLevel } from '../../services/validator/ValidationError';

const { Search } = Input;

type XZuFiResultHintProps = {
  errors: ValidationError[];
};

type XZuFiResultHintState = {
  searchString: string;
  showWarnings: boolean;
};

class XZuFiResultHints extends React.Component<XZuFiResultHintProps, XZuFiResultHintState> {

  public constructor(props: XZuFiResultHintProps) {
    super(props);

    this.state = {
      searchString: '',
      showWarnings: true,
    };

    this.filterHints = this.filterHints.bind(this);
    this.toggleWarnings = this.toggleWarnings.bind(this);
  }

  private filterHints(): ValidationError[] {
    const output: ValidationError[] = [];

    const searchString = this.state.searchString;
    const showWarnings = this.state.showWarnings;

    for (const error of this.props.errors) {
      if (!showWarnings && error.errorCodeLevel === ValidationErrorLevel.WARNING) {
        continue;
      }

      if (!error.message.toUpperCase().includes(searchString.toUpperCase())) {
        continue;
      }

      output.push(error);
    }

    return output;
  }

  private toggleWarnings(checked: boolean) {
    this.setState({
      showWarnings: checked,
    });
  }

  private onSearchChange(term: string) {
    this.setState({
      searchString: term,
    });
  }

  private getErrorMessage(error: ValidationError): string {
    let message = `${error.message}`;

    if (error.line !== 0) {
      message = `Zeile ${error.line} - ` + message;
    }

    return message;
  }

  public render(): ReactElement {
    if (this.props.errors.length === 0) {
      return (
        <div>
          <Alert
            message="Es gibt keine Hinweise."
            type="info"
            className="alert"
            showIcon
          />
        </div>
      );
    }

    const errors = this.filterHints();

    return (
      <div>
        <div className="hint-toolbar">
          <Search
            className="hint-toolbar-search"
            allowClear
            placeholder="Suche..."
            onChange={(ev: any) => { this.onSearchChange(ev.target.value); }}
          />
          <div>
            <span className="hint-toolbar-warning-label">
              Warnungen
            </span>
            <Switch
              defaultChecked
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(checked: boolean) => { this.toggleWarnings(checked); }}
            />
          </div>
        </div>
        {errors.map((error: ValidationError, key: number) =>
          <Alert
            message={this.getErrorMessage(error)}
            key={key}
            type={error.errorCodeLevel}
            className="alert"
            showIcon={true}
          />,
        )}
      </div>
    );
  }
}

export default XZuFiResultHints;
