
import './DocumentationLayout.css';

import { LoadingOutlined } from '@ant-design/icons';
import { Layout, Menu, Spin } from 'antd';
import React, { ReactElement } from 'react';
import ReactMarkdown from 'react-markdown';
import { Navigate } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import { withRouter, WithRouterProps } from '../services/withRouter';
import ParticularitiesMarkdown from './Besonderheiten.md';
import DocumentationMarkdown from './Documentation.md';
import GlossarMarkdown from './Glossar.md';
import RulesMarkdown from './Rules.md';
import ToperrorsMarkdown from './Toperrors.md';
import UebersichtMarkdown from './Uebersicht.md';

type DocumentationLayoutProps = WithRouterProps<DocumentationLayoutRouteProps> & {
}

type DocumentationLayoutRouteProps = {
  documentationID: string
}

type DocumentationLayoutState = {
  markdown: Record<string, string>
  redirectTo: string
}

const { Sider, Content } = Layout;

class DocumentationLayout extends React.Component<DocumentationLayoutProps, DocumentationLayoutState> {
  private readonly fileMap: Record<string, { title: string, doc: string }> = {
    index: {
      title: 'Dokumentation',
      doc: DocumentationMarkdown,
    },
    benutzeroberflaeche: {
      title: 'Benutzeroberfläche',
      doc: UebersichtMarkdown,
    },
    regeln: {
      title: 'Regeln',
      doc: RulesMarkdown,
    },
    besonderheiten: {
      title: 'Besonderheiten',
      doc: ParticularitiesMarkdown,
    },
    topfehler: {
      title: 'Häufige Fehler',
      doc: ToperrorsMarkdown,
    },
    glossar: {
      title: 'Glossar',
      doc: GlossarMarkdown,
    },
  };

  public constructor(props: DocumentationLayoutProps) {
    super(props);

    this.state = {
      markdown: {},
      redirectTo: '',
    };

    this.buildMenu = this.buildMenu.bind(this);
    this.getRedirect = this.getRedirect.bind(this);
    this.openDoc = this.openDoc.bind(this);
  }

  public async componentDidMount() {
    for (const name in this.fileMap) {
      await fetch(this.fileMap[name].doc)
        .then(async response => response.text())
        .then((text) => {
          const md = this.state.markdown;

          md[name] = text;

          this.setState({
            markdown: md,
          });
        });
    }
  }

  private buildMenu(): ReactElement[] {
    const output: ReactElement[] = [];

    for (const name in this.fileMap) {
      output.push(
        <Menu.Item key={name}>{this.fileMap[name].title}</Menu.Item>,
      );
    }

    return output;
  }

  private getRedirect(): string {
    const documentationID = this.props.match.params.documentationID;
    let redirectTo = this.state.redirectTo;

    if (documentationID === '' && this.state.redirectTo === '') {
      redirectTo = '/dokumentation/index';
    }

    if (!(documentationID in this.state.markdown) && this.state.redirectTo === '') {
      redirectTo = '/dokumentation/index';
    }

    return redirectTo;
  }

  private openDoc(key: string) {
    this.setState({
      redirectTo: `/dokumentation/${key}`,
    });
  }

  public render() {
    const readMarkdownCount = Object.keys(this.state.markdown).length;
    const totalMarkdownCount = Object.keys(this.fileMap).length;

    if (readMarkdownCount !== totalMarkdownCount) {
      return (
        <div className="loading-spinner" hidden={readMarkdownCount === totalMarkdownCount}>
          <Spin indicator={<LoadingOutlined />} />
        </div>
      );
    }

    const redirectTo = this.getRedirect();
    if (redirectTo !== '' && redirectTo !== this.props.location.pathname) {
      return (
        <Navigate
          to={redirectTo}
          replace
          state={{ location: this.props.location }}
        />
      );
    }

    const documentationID = this.props.match.params.documentationID;

    return (
      <Layout className="layout-content">
        <Sider className="doc-sider">
          <Menu
            mode="vertical"
            style={{ height: '100%' }}
            defaultSelectedKeys={[documentationID]}
            selectedKeys={[documentationID]}
            onSelect={(item: any): void => {
              this.openDoc(item.key);
            }}
          >
            { this.buildMenu() }
          </Menu>
        </Sider>
        <Content>
          <div>
            <ReactMarkdown className="markdown" rehypePlugins={[rehypeRaw, remarkGfm]}>
              {this.state.markdown[documentationID]}
            </ReactMarkdown>
          </div>
        </Content>
      </Layout>
    );
  }
}

export default withRouter(DocumentationLayout);
