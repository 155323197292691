import './index.css';

import { LoadingOutlined } from '@ant-design/icons';
import { Collapse, Result, Spin, Tabs, Tag } from 'antd';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';

import { ValidationError, ValidationErrorLevel } from '../../services/validator/ValidationError';
import { ZuFi } from '../../services/validator/ZuFi';
import RuleSet from '../RuleSet';
import XZuFiResultDetails from '../XZuFiResultDetails';
import XZuFiResultHints from '../XZuFiResultHints';
import XZuFiResultPretty from '../XZuFiResultPretty';
import { getLoading, getValidatorResult, getXML } from './duck';

const { Panel } = Collapse;
const { TabPane } = Tabs;

type ResultProps = {
  dispatch: Function;
  errors: ValidationError[];
  zufi: ZuFi;
  xml: string;
  format: string;
  loading: boolean;
}

type ResultState = {
  activeTab: string;
}

class XZuFiResult extends React.Component<ResultProps, ResultState> {

  public constructor(props: ResultProps) {
    super(props);

    this.state = {
      activeTab: '1',
    };

    this.buildResultHeader = this.buildResultHeader.bind(this);
    this.onTabClick = this.onTabClick.bind(this);
  }

  public componentDidUpdate(prevProps: ResultProps) {
    if (this.props.errors.length === prevProps.errors.length) {
      return;
    }

    const fixedTabs = ['3', '4'];

    if (fixedTabs.includes(this.state.activeTab)) {
      return;
    }

    let activeTab = '1';

    if (this.props.errors.length >= 1) {
      activeTab = '2';
    }

    this.setState({
      activeTab,
    })
  }

  private onTabClick(key: string) {
    this.setState({
      activeTab: key,
    });
  }

  private buildResultHeader(): ReactElement {
    if (this.getErrorCount() > 0) {
      return (
        <Result
          status="error"
          title="XZuFi enthält Fehler"
        />
      )
    }

    if (this.getWarningCount() > 0) {
      return (
        <Result
          status="warning"
          title="XZuFi enthält Warnungen"
        />
      )
    }

    return (
      <Result
        status="success"
        title="XZuFi wurde erfolgreich validiert"
      />
    )
  }

  private getErrorCount(): number {
    let counter = 0

    for (const error of this.props.errors) {
      if (error.errorCodeLevel !== ValidationErrorLevel.ERROR) {
        continue
      }

      counter++
    }

    return counter
  }

  private getWarningCount(): number {
    let counter = 0

    for (const error of this.props.errors) {
      if (error.errorCodeLevel !== ValidationErrorLevel.WARNING) {
        continue
      }

      counter++
    }

    return counter
  }

  public render() {
    if (this.props.xml.length === 0) {
      return (
        <div className="output-result no-document">
          <h1 style={{
            fontSize: 42,
            textAlign: 'center',
            opacity: 0.20,
          }}>
            Bitte geben Sie das Dokument auf der linken Seite ein, das Sie validieren wollen!
          </h1>
        </div>
      );
    }
    const isValid = this.props.errors.length === 0;

    return (
      <div className="output-result">
        <div className="loading-spinner" hidden={!this.props.loading}>
          <Spin indicator={<LoadingOutlined />} />
        </div>
        <div className={this.props.loading ? 'loading' : ''} style={{ margin: '16px' }}>
          {this.buildResultHeader()}
          <Tabs
            defaultActiveKey={isValid ? '1' : '2'}
            activeKey={this.state.activeTab}
            centered={true}
            className="tabview"
            onTabClick={(key: string) => {
              this.onTabClick(key);
            }}
            style={{
              height: '100%',
            }}
          >
            <TabPane tab="Details" key="3">
              <XZuFiResultDetails
                format={this.props.format}
                services={this.props.zufi.services}
                organizationalUnits={this.props.zufi.orgunits}
                onlineServices={this.props.zufi.onlineservices}
                competences={this.props.zufi.competences}
              />
            </TabPane>
            <TabPane tab="ZuFi" key="1">
              <XZuFiResultPretty
                services={this.props.zufi.services}
                organizationalUnits={this.props.zufi.orgunits}
                onlineServices={this.props.zufi.onlineservices}
                competences={this.props.zufi.competences}
              />
            </TabPane>
            <TabPane
              tab={
                <span>
                  Hinweise
                  { this.getErrorCount() > 0 &&
                    <Tag color="red" className="hint-count">{this.getErrorCount()}</Tag>
                  }
                  { this.getWarningCount() > 0 &&
                    <Tag color="warning" className="hint-count">{this.getWarningCount()}</Tag>
                  }
                </span>
              }
              key="2"
            >
              <XZuFiResultHints errors={this.props.errors} />
            </TabPane>
            <TabPane tab="Regelwerk" key="4">
              <RuleSet />
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default connect((state: any) => ({
  zufi: getValidatorResult(state).model,
  errors: getValidatorResult(state).errors,
  format: getValidatorResult(state).format,
  xml: getXML(state),
  loading: getLoading(state),
}))(XZuFiResult);
