import { Collapse, Descriptions } from 'antd';
import React from 'react';

import { Competence, OnlineService, OrgUnit, Service } from '../../services/validator/ZuFi';

type XZuFiResultDetailsProps = {
  format: string;
  services: Service[];
  organizationalUnits: OrgUnit[];
  onlineServices: OnlineService[];
  competences: Competence[];
};

type XZuFiResultDetailsState = {

};

class XZuFiResultDetails extends React.Component<XZuFiResultDetailsProps, XZuFiResultDetailsState> {
  public render(): React.ReactNode {
    return (
      <div>
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" label="Format">
            {this.props.format === '' ? 'nicht erkannt' : this.props.format}
          </Descriptions.Item>
          <Descriptions.Item className="details-row" label="Erkannte Zuständigkeiten">
            {this.props.competences.length}
          </Descriptions.Item>
          <Descriptions.Item className="details-row" label="Erkannte Leistungen">
            {this.props.services.length}
          </Descriptions.Item>
          <Descriptions.Item className="details-row" label="Erkannte Organisationseinheiten">
            {this.props.organizationalUnits.length}
          </Descriptions.Item>
          <Descriptions.Item className="details-row" label="Erkannte Onlinedienste">
            {this.props.onlineServices.length}
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default XZuFiResultDetails;
