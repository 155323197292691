import { Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { Communication } from '../services/validator/ZuFi';
import PrettyValueOrDefault from './PrettyValueOrDefault';

type CommunicationProps = {
  communication: Communication;
  index: number;
};

type CommunicationState = {

}

type CommunicationPropDetailsType = {
  channel: TooltipDetails;
  identifier: TooltipDetails;
  additionalIdentifier: TooltipDetails;
  addition: TooltipDetails;
  validFrom: TooltipDetails;
  validUntil: TooltipDetails;
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const CommunicationPropDetails: CommunicationPropDetailsType = {
  channel: {
    label: 'kanal',
    tooltip: {
      title: 'kanal [1]',
      content: (
        <div>
          Der "kanal" gibt an, über welchen Kommunikationskanal eine Erreichbarkeit besteht. Unterstützte Werte sind:<br /><br />
          001 → Telefonnumer eines Festnetzanschlusses; alternativer Alias: telefon<br />
          002 → Telefonnummer eines Mobilfunkanschlusses; alternativer Alias: mobil<br />
          003 → Telefonnummer eines Faxanschlusses; alternativer Alias: fax<br />
          004 → E-Mail-Adresse; alternativer Alias: email<br /><br />
          Siehe: xzufi:Code.Kommunikationskanal \// urn:de:xfalldaten:kommunikationskanal
        </div>
      ),
    },
  },
  identifier: {
    label: 'kennung',
    tooltip: {
      title: 'kennung [1]',
      content: 'Die "kennung" beinhaltet die konkreten Angaben zur Erreichbarkeit über einen Kommunikationskanal, d.h. die Telefonnummer, Faxnummer, E-Mail-Adresse oder dergleichen. Die Kennung soll strukturiert erfasst werden.',
    },
  },
  additionalIdentifier: {
    label: 'kennungzusatz',
    tooltip: {
      title: 'kennungzusatz [0…1]',
      content: 'Optionaler Zusatz zur Kennung. Hier sollen keine Bemerkungen zur Erreichbarkeit o.ä. eingetragen werden. Es handelt sich um einen optionalen Zusatz der eigentlichen Kennung.',
    },
  },
  addition: {
    label: 'zusatz',
    tooltip: {
      title: 'zusatz [0…1]',
      content: 'Dieser Zusatz diet zu Bemerkungen zur Kommunikation, z.B. Angaben über Kosten für Telefonnummern oder zur Erreichbarkeit.',
    },
  },
  validFrom : {
    label: 'Gültigkeitsbeginn',
    tooltip: {
      title: 'gueltigkeit > beginn [0…n]',
      content: 'Beginn der Gültigkeit',
    },
  },
  validUntil : {
    label: 'Gültigkeitsende',
    tooltip: {
      title: 'gueltigkeit > ende [0…n]',
      content: 'Ende der Gültigkeit',
    },
  },
};

class CommunicationComponent extends React.Component<CommunicationProps, CommunicationState> {
  private descriptionProps(key: string): any {
    const details: TooltipDetails = CommunicationPropDetails[key as keyof CommunicationPropDetailsType];

    if (details === undefined) {
      return {};
    }

    return {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };
  }

  public render(): ReactNode {
    return (
      <div className="address">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('channel')}>
            <PrettyValueOrDefault value={this.props.communication.channel} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('identifier')}>
            <PrettyValueOrDefault value={this.props.communication.identifier} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('additionalIdentifier')}>
            <PrettyValueOrDefault value={this.props.communication.additionalIdentifier} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('addition')}>
            <PrettyValueOrDefault value={this.props.communication.addition} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validFrom')}>
            <PrettyValueOrDefault value={this.props.communication.validFrom} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validUntil')}>
            <PrettyValueOrDefault value={this.props.communication.validUntil} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default CommunicationComponent;
