import './Service.css';

import { Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { Competence, OnlineService, OrgUnit, Service } from '../services/validator/ZuFi';
import DefaultValue from './DefaultValue';
import PrettyValueOrDefault from './PrettyValueOrDefault';

type CompetenceComponentProps = {
  competence: Competence;
  services: Service[];
  orgUnits: OrgUnit[];
  onlineServices: OnlineService[];
  key: number;
  index: number;
  openServiceCallback: Function;
  openOrgUnitCallback: Function;
  openOnlineServiceCallback: Function;
}

type CompetenceComponentState = {

}

type CompetencePropDetailsType = {
  serviceID: TooltipDetails;
  serviceName: TooltipDetails;
  orgUnitID: TooltipDetails;
  orgUnitName: TooltipDetails;
  onlineServiceID: TooltipDetails;
  onlineServiceURL: TooltipDetails;
  areaID: TooltipDetails,
  areaIDType: TooltipDetails,
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const CompetencePropDetails: CompetencePropDetailsType = {
  serviceID: {
    label: 'Leistung ID',
    tooltip: {
      title: 'idLeistung [1]',
      content: 'Eindeutige ID der Leistung',
    },
  },
  serviceName: {
    label: 'Leistung Beschreibung',
    tooltip: {
      title: 'Beschreibung der Leistung',
      content: '',
    },
  },
  orgUnitID: {
    label: 'Organisationseinheit ID',
    tooltip: {
      title: 'id [1]',
      content: 'Eindeutige ID der Organisationseinheit',
    },
  },
  orgUnitName: {
    label: 'Organisationseinheit Beschreibung',
    tooltip: {
      title: 'Beschreibung der Organisationseinheit',
      content: '',
    },
  },
  onlineServiceID: {
    label: 'Onlinedienst ID',
    tooltip: {
      title: 'id [1]',
      content: 'Eindeutige ID des Onlinedienstes',
    },
  },
  onlineServiceURL: {
    label: 'Onlinedienst URL',
    tooltip: {
      title: 'URL des Onlinedienstes',
      content: '',
    },
  },
  areaID: {
    label: 'Gebiet ID',
    tooltip: {
      title: 'gebietID [1..n]',
      content: 'ID des Gebiets der Zuständigkeit',
    },
  },
  areaIDType: {
    label: 'Gebiet ID Typ',
    tooltip: {
      title: 'Typ der Gebiets-ID',
      content: '',
    },
  },
};

class CompetenceComponent extends React.Component<CompetenceComponentProps, CompetenceComponentState> {
  private getServiceName(id: string): ReactNode {
    for (const svc of this.props.services) {
      if (svc.id !== id) {
        continue;
      }

      return <PrettyValueOrDefault value={svc.name} />;
    }

    return <DefaultValue />;
  }

  private getOrgUnitName(id: string): ReactNode {
    for (const ou of this.props.orgUnits) {
      if (ou.id !== id) {
        continue;
      }

      return <PrettyValueOrDefault value={ou.name} />;
    }

    return <DefaultValue />;
  }

  private getOnlineServiceURL(id: string): ReactNode {
    for (const os of this.props.onlineServices) {
      if (os.id !== id) {
        continue;
      }

      return <PrettyValueOrDefault value={os.url} />;
    }

    return <DefaultValue />;
  }

  private descriptionProps(key: string): any {
    const details: TooltipDetails = CompetencePropDetails[key as keyof CompetencePropDetailsType];

    if (details === undefined) {
      return {};
    }

    return {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };
  }

  private onServiceClick(svcID: string) {
    this.props.openServiceCallback(`svc-${svcID}`);
  }

  private onOrgUnitClick(ouID: string) {
    this.props.openOrgUnitCallback(`ou-${ouID}`);
  }

  private onOnlineServiceClick(osID: string) {
    this.props.openOnlineServiceCallback(`os-${osID}`);
  }

  public render(): React.ReactNode {
    return (
      <div className="leistung">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('serviceID')}>
            { this.props.competence.serviceID === ''
              ? <DefaultValue />
              : <AnchorLink
                onClick={() => { this.onServiceClick(this.props.competence.serviceID); }}
                href={`#svc-${this.props.competence.serviceID}`}
              >
                <PrettyValueOrDefault value={this.props.competence.serviceID} />
              </AnchorLink>
            }
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('serviceName')}>
            {this.getServiceName(this.props.competence.serviceID)}
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('orgUnitID')}>
            { this.props.competence.orgUnitID === ''
              ? <DefaultValue />
              : <AnchorLink
                onClick={() => { this.onOrgUnitClick(this.props.competence.orgUnitID); }}
                href={`#ou-${this.props.competence.orgUnitID}`}
              >
                <PrettyValueOrDefault value={this.props.competence.orgUnitID} />
              </AnchorLink>
            }
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('orgUnitName')}>
            {this.getOrgUnitName(this.props.competence.orgUnitID)}
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('onlineServiceID')}>
            { this.props.competence.onlineServiceID === ''
              ? '-'
              : <AnchorLink
                onClick={() => { this.onOnlineServiceClick(this.props.competence.onlineServiceID); }}
                href={`#os-${this.props.competence.onlineServiceID}`}
              >
                <PrettyValueOrDefault value={this.props.competence.onlineServiceID} />
              </AnchorLink>
            }
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('onlineServiceURL')}>
            {this.getOnlineServiceURL(this.props.competence.onlineServiceID)}
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('areaID')}>
            <PrettyValueOrDefault value={this.props.competence.areaID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('areaIDType')}>
            <PrettyValueOrDefault value={this.props.competence.areaIDType} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default CompetenceComponent;
