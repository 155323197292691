import { Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { Cost } from '../services/validator/ZuFi';
import PrettyValueOrDefault from './PrettyValueOrDefault';


type CostsProps = {
  costs: Cost;
};

type CostsState = {

};

type CostsPropDetailsType = {
  type: TooltipDetails,
  description: TooltipDetails,
  url: TooltipDetails,
  fixedCosts: TooltipDetails,
  minCosts: TooltipDetails,
  maxCosts: TooltipDetails,
  variableDescription: TooltipDetails,
  noCosts: TooltipDetails,
  prepayment: TooltipDetails,
  urlComposition: TooltipDetails,
  checkoutSign: TooltipDetails,
  paymentMethod: TooltipDetails,
  position: TooltipDetails,
  validFrom: TooltipDetails,
  validUntil: TooltipDetails,
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const CostsPropDetails: CostsPropDetailsType = {
  type: {
    label: 'Typ',
    tooltip: {
      title: 'kosten > typ > code',
      content: undefined,
    },
  },
  description: {
    label: 'Beschreibung',
    tooltip: {
      title: 'kosten > beschreibung',
      content: undefined,
    },
  },
  url: {
    label: 'Weiterführender Link',
    tooltip: {
      title: 'weiterfuehrenderLink',
      content: undefined,
    },
  },
  fixedCosts: {
    label: 'Fixe Kosten',
    tooltip: {
      title: 'kosten > betrag',
      content: undefined,
    },
  },
  minCosts: {
    label: 'Kosten Untergrenze',
    tooltip: {
      title: 'kosten > betragUntergrenze',
      content: undefined,
    },
  },
  maxCosts: {
    label: 'Kosten Obergrenze',
    tooltip: {
      title: 'kosten > betragObergrenze',
      content: undefined,
    },
  },
  variableDescription: {
    label: 'Beschreibung Variabilität',
    tooltip: {
      title: 'kosten > beschreibungVariabilitaet',
      content: undefined,
    },
  },
  noCosts: {
    label: 'Kostenfrei',
    tooltip: {
      title: 'kosten > beschreibungKostenfreiheit',
      content: undefined,
    },
  },
  prepayment: {
    label: 'Vorkasse',
    tooltip: {
      title: 'vorkasse',
      content: undefined,
    },
  },
  urlComposition: {
    label: 'Link Kostenbildung',
    tooltip: {
      title: 'linkKostenbildung',
      content: undefined,
    },
  },
  checkoutSign: {
    label: 'Kassenzeichen',
    tooltip: {
      title: 'kassenzeichen',
      content: undefined,
    },
  },
  paymentMethod: {
    label: 'Zahlungsweise',
    tooltip: {
      title: 'zahlungsweise',
      content: undefined,
    },
  },
  position: {
    label: 'Position Darstellung',
    tooltip: {
      title: 'positionDarstellung',
      content: undefined,
    },
  },
  validFrom: {
    label: 'Gültig ab',
    tooltip: {
      title: 'gueltigkeit > beginn',
      content: undefined,
    },
  },
  validUntil: {
    label: 'Gültig bis',
    tooltip: {
      title: 'gueltigkeit > ende',
      content: undefined,
    },
  },
};

class CostsComponent extends React.Component<CostsProps, CostsState> {
  private descriptionProps(key: string): any {
    const details: TooltipDetails = CostsPropDetails[key as keyof CostsPropDetailsType];

    if (details === undefined) {
      return {};
    }

    return {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };
  }

  public render(): ReactNode {
    return (
      <div className="leistung">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('type')}>
            <PrettyValueOrDefault value={this.props.costs.type} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('description')}>
            <PrettyValueOrDefault value={this.props.costs.description} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('url')}>
            <PrettyValueOrDefault value={this.props.costs.url} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('fixedCosts')}>
            <PrettyValueOrDefault value={this.props.costs.fixedCosts} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('minCosts')}>
            <PrettyValueOrDefault value={this.props.costs.minCosts} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('maxCosts')}>
            <PrettyValueOrDefault value={this.props.costs.maxCosts} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('variableDescription')}>
            <PrettyValueOrDefault value={this.props.costs.variableDescription} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('noCosts')}>
            <PrettyValueOrDefault value={this.props.costs.noCosts} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('prepayment')}>
            <PrettyValueOrDefault value={this.props.costs.prepayment} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('urlComposition')}>
            <PrettyValueOrDefault value={this.props.costs.urlComposition} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('checkoutSign')}>
            <PrettyValueOrDefault value={this.props.costs.checkoutSign} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('paymentMethod')}>
            <PrettyValueOrDefault value={this.props.costs.paymentMethod} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('position')}>
            <PrettyValueOrDefault value={this.props.costs.position} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validFrom')}>
            <PrettyValueOrDefault value={this.props.costs.validFrom} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validUntil')}>
            <PrettyValueOrDefault value={this.props.costs.validUntil} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default CostsComponent;
