import './Service.css';

import { Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { OnlineService } from '../services/validator/ZuFi';
import PrettyValueOrDefault from './PrettyValueOrDefault';

type OnlineServiceComponentProps = {
  onlineService: OnlineService;
  key: number;
  index: number;
}

type OnlineServiceComponentState = {

}

type OnlineServicePropDetailsType = {
  id: TooltipDetails;
  url: TooltipDetails;
  description: TooltipDetails;
  trustLevel: TooltipDetails;
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const OnlineServicePropDetails: OnlineServicePropDetailsType = {
  id: {
    label: 'ID',
    tooltip: {
      title: 'id [1]',
      content: 'Eindeutige ID der Leistung',
    },
  },
  url: {
    label: 'URL',
    tooltip: {
      title: 'link > link [0…n]',
      content: '',
    },
  },
  description: {
    label: 'Titel',
    tooltip: {
      title: 'link > titel [0…n]',
      content: '',
    },
  },
  trustLevel: {
    label: 'Vertrauensniveau',
    tooltip: {
      title: 'vertrauensniveau [0…n]',
      content: '',
    },
  },
};

class OnlineServiceComponent extends React.Component<OnlineServiceComponentProps, OnlineServiceComponentState> {
  private descriptionProps(key: string): any {
    const details: TooltipDetails = OnlineServicePropDetails[key as keyof OnlineServicePropDetailsType];

    if (details === undefined) {
      return {};
    }

    return {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };
  }

  public render(): React.ReactNode {
    return (
      <div className="leistung">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('id')}>
            <PrettyValueOrDefault value={this.props.onlineService.id} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('url')}>
            <a
              target={'_blank'}
              href={this.props.onlineService.url}
            >
              <PrettyValueOrDefault value={this.props.onlineService.url} />
            </a>
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('description')}>
            <PrettyValueOrDefault value={this.props.onlineService.description} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('trustLevel')}>
            <PrettyValueOrDefault value={this.props.onlineService.trustLevel} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default OnlineServiceComponent;
