import * as types from './actions';


export const defaultState = {
  darkMode: false,
};

const defaultAction = {
  type: '',
  payload: {},
};

export default function reducer(
  state: any = defaultState,
  action: { type: string; payload: any } = defaultAction,
) {
  switch (action.type) {
  case types.THEME_TOGGLE:
    return {
      ...state,
      darkMode: !state.darkMode,
    };

  default:
    return state;
  }
}
