import './BaseLayout.css';

import {
  BulbFilled, BulbOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu } from 'antd'
import React from 'react'
import { connect } from 'react-redux';
import { Link, Route, Routes } from 'react-router-dom';

const {
  Header, Footer, Content, Sider,
} = Layout;

import { version } from '../services/validator';
import { themeToggle } from '../store/system/actions';
import { getDarkMode } from '../store/system/selectors';
import DocumentationLayout from './DocumentationLayout';
import ImprintLayout from './Imprint';
import PrivacyLayout from './Privacy';
import ValidatorLayout from './ValidatorLayout';

type BaseLayoutProps = {
  dispatch: Function;
  darkMode: boolean;
}

type BaseLayoutState = {
  darkMode: boolean;
  apiVersion: string;
}

class BaseLayout extends React.Component<BaseLayoutProps, BaseLayoutState> {

  public constructor(props: BaseLayoutProps) {
    super(props);

    this.state = {
      darkMode: false,
      apiVersion: '-',
    };

    this.toggleDarkMode = this.toggleDarkMode.bind(this);
  }

  public async componentDidMount() {
    const apiVersion = await version();

    this.setState({
      apiVersion,
    });
  }

  private toggleDarkMode() {
    this.props.dispatch(themeToggle())
  }

  public render() {
    if (this.props.darkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }

    return (
      <Layout className="layout">
        <Sider width="0px" />
        <Layout className="site-layout">
          <Header className="site-layout-background header">
            <h1 style={{ marginBottom: '0' }}>XZuFi Validator</h1>
            <Menu
              mode="horizontal"
              className="navmenu"
              defaultSelectedKeys={['1']}
            >
              <Menu.Item key="1">
                <Link to="/">Validator</Link>
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/dokumentation">Dokumentation</Link>
              </Menu.Item>
            </Menu>
            <Button type="text" className="darkmode" onClick={() => {
              this.toggleDarkMode();
            }}>
              {this.props.darkMode ? <BulbFilled /> : <BulbOutlined />}
            </Button>
          </Header>
          <Content className="card-content">
            <Routes>
              <Route path="/dokumentation" element={<DocumentationLayout />}>
                <Route path=":documentationID" element={<DocumentationLayout />} />
              </Route>
              <Route path="/datenschutz" element={<PrivacyLayout />} />
              <Route path="/impressum" element={<ImprintLayout />} />
              <Route path="" element={<ValidatorLayout />} />
            </Routes>
          </Content>
          <Footer className="footer">
            publicplan GmbH © 2022 | UI Version: {window.env.APP_VERSION} | API Version: {this.state.apiVersion} | <Link to="/impressum">Impressum</Link> | <Link to="/datenschutz">Datenschutzerklärung</Link>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default connect((state: any) => {
  return {
    darkMode: getDarkMode(state),
  };
})(BaseLayout);
