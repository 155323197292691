import * as types from './actions';

export const defaultState = {
  loading: false,
  rules: {},
  error: '',
};

const defaultAction = {
  type: '',
  payload: {},
}

export default function reducer(
  state: any = defaultState,
  action: { type: string, payload: any } = defaultAction,
) {
  switch (action.type) {
  case types.RULES_LOADING:
    return {
      ...state,
      loading: true,
    };
  case types.RULES_SUCCESS:
    return {
      ...state,
      loading: false,
      rules: action.payload.rules,
      error: '',
    };
  case types.RULES_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload.reason,
    };
  default:
    return state;
  }
}
