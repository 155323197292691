import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore } from 'redux-persist'
import thunkMiddleware from 'redux-thunk';

import loggerMiddleware from './middlewares/logger';
import rootReducer from './root-reducer';
import { default as configureStoreDev } from './store.dev';
import { default as configureStoreProd } from './store.prod';

let configureStore = configureStoreProd;
if (process.env.NODE_ENV === 'development') {
  configureStore = configureStoreDev;
}

export default configureStore;

const middlewares = [
  thunkMiddleware,
  loggerMiddleware,
];

const enhancer = compose(applyMiddleware(...middlewares));

export const store = createStore(rootReducer, enhancer);
export const persistor = persistStore(store);

// reset store on a new app version

let currentAppVersion = '';
if (window.env?.APP_VERSION) {
  currentAppVersion = window.env.APP_VERSION;
}

const appVersion = localStorage.getItem('app_version');

if (appVersion !== currentAppVersion) {
  localStorage.setItem('app_version', currentAppVersion);
  void persistor.flush().then();
}
