import * as types from './actions';

export const defaultState = {
  loading: false,
  xml: '',
  format: 'auto',
  result: {
    errors: [],
    model: {
      services: [],
      orgunits: [],
      onlineservices: [],
      competences: [],
    },
    format: '',
  },
  error: '',
};

const defaultAction = {
  type: '',
  payload: {},
};

export default function reducer(
  state: any = defaultState,
  action: { type: string, payload: any } = defaultAction,
) {
  switch (action.type) {
  case types.LOADING:
    return {
      ...state,
      loading: true,
    };
  case types.CHANGE_XML:
    return {
      ...state,
      xml: action.payload.xml,
    };
  case types.CHANGE_FORMAT:
    return {
      ...state,
      format: action.payload.format,
    };
  case types.VALIDATOR_RESULT:
    let model = action.payload.result.model;

    if (Array.isArray(model)) {
      model = {
        services: [],
        orgunits: [],
        onlineservices: [],
        competences: [],
      };
    }

    return {
      ...state,
      loading: false,
      result: {
        errors: action.payload.result.errors,
        model,
        format: action.payload.result.format,
      },
    };
  case types.VALIDATOR_FAILURE:
    return {
      ...state,
      loading: false,
      error: action.payload.reason,
    };
  default:
    return state;
  }
}
