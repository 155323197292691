//
// Action Types
//

export const THEME_TOGGLE = 'actions/html/THEME_CHANGE';


//
// Action Creators
//

export function themeToggle() {
  return {
    type: THEME_TOGGLE,
  };
}
