export const RULES_LOADING = 'actions/rules/LOADING';
export const RULES_SUCCESS = 'actions/rules/SUCCESS';
export const RULES_FAILURE = 'actions/rules/FAILURE';

export function rulesLoading() {
  return {
    type: RULES_LOADING,
    payload: {},
  };
}

export function rulesSuccess(rules: any) {
  return {
    type: RULES_SUCCESS,
    payload: {
      rules,
    },
  };
}

export function rulesFailure(reason: string) {
  return {
    type: RULES_FAILURE,
    payload: {
      reason,
    },
  };
}
