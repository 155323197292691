import { ValidatorAPIResponse } from '../../../services/validator';

export function getValidatorState(state: any): any {
  return state.validator;
}

export function getLoading(state: any): boolean {
  return getValidatorState(state).loading;
}

export function getXML(state: any): string {
  return getValidatorState(state).xml;
}

export function getFormat(state: any): string {
  return getValidatorState(state).format;
}

export function getValidatorResult(state: any): ValidatorAPIResponse {
  return getValidatorState(state).result;
}
