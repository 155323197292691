import './ValidatorLayout.css';

import { Layout } from 'antd';
import React from 'react';

import Result from '../components/XZuFiResult';
import Validator from '../components/XZuFiXMLInput';

type ValidatorLayoutProps = {

}

type ValidatorLayoutState = {

}

class ValidatorLayout extends React.Component<ValidatorLayoutProps, ValidatorLayoutState> {
  public render() {
    return (
      <Layout className="layout-content grid-layout">
        <Validator />
        <Result />
      </Layout>
    );
  }
}

export default ValidatorLayout;
