import './Privacy.css';

import { Layout } from 'antd';
import React from 'react';

const { Content } = Layout;

type PrivacyLayoutProps = {

}

type PrivacyLayoutState = {

}

class PrivacyLayout extends React.Component<PrivacyLayoutProps, PrivacyLayoutState> {
  public render() {
    return (
      <Layout className="layout-content">
        <Content className="privacy-content">
          <h1>Datenschutzerklärung</h1>
          <p>
            Wir nehmen den Schutz Ihrer personenbezogenen Daten sehr ernst. Mit dieser Datenschutzerklärung erläutern wir, welche Art von Daten wir bei Ihrem Besuch der Informations-und Dienstleistungsdatenbank NRW erhoben und zu welchem Zweck sie verwendet werden.
          </p>

          <h2>Datenschutzbestimmungen</h2>
          <p>
            Als Teil der Landesverwaltung unterliegt die Informations-und Dienstleistungsdatenbank NRW den Bestimmungen des Nordrhein-Westfälischen Datenschutzgesetzes sowie der EU-Datenschutz-Grundverordnung. Wir stellen sicher, dass die Vorschriften über den Datenschutz auch von beteiligten externen Dienstleistern beachtet werden.
          </p>

          <h2>Kontaktdaten der für die Verarbeitung Verantwortlichen Stelle und der Datenschutzbeauftragten:</h2>

          <h3>Verantwortlich für die Datenverarbeitung ist das:</h3>
          <p>
            Ministerium für Heimat, Kommunales, Bau und Digitalisierung des Landes Nordrhein-Westfalen<br />
            Jürgensplatz 1, 40219 Düsseldorf<br />
            Telefon: 0211-8618-50<br />
            E-Mail: <a href="mailto:poststelle@mhkbd.nrw.de">poststelle@mhkbd.nrw.de</a>
          </p>

          <h3>Kontaktdaten der Datenschutzbeauftragten:</h3>
          <p>
            Ministerium für Heimat, Kommunales, Bau und Digitalisierung des Landes Nordrhein-Westfalen<br />
            Behördlicher Datenschutzbeauftragter<br />
            Jürgensplatz 1, 40219 Düsseldorf<br />
            Telefon: 0211-8618-50<br />
            E-Mail: <a href="mailto:datenschutzbeauftragter@mhkbd.nrw.de">datenschutzbeauftragter@mhkbd.nrw.de</a>
          </p>

          <h2>Ihre Rechte als Nutzer</h2>
          <p>
            Ihnen stehen bei Vorliegen der gesetzlichen Voraussetzungen folgende Rechte zu:

            <ul>
              <li>Recht auf Auskunft über Sie betreffende personenbezogene Daten (Artikel 15 DS-GVO),</li>
              <li>Recht auf Berichtigung Sie betreffender unrichtiger personenbezogener Daten(Artikel 16 DS-GVO),</li>
              <li>Recht auf Löschung personenbezogener Daten (Artikel 17 DS-GVO),</li>
              <li>Recht auf Einschränkung der Verarbeitung personenbezogener Daten (Artikel 18 DS-GVO),</li>
              <li>Recht auf Widerspruch gegen die Verarbeitung personenbezogener Daten (Artikel 21 DS-GVO)</li>
            </ul>
          </p>

          <p>
            Außerdem haben Sie nach Artikel 77 DS-GVO das Recht, sich bei der Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass eine nicht rechtmäßige Verarbeitung der Sie betreffenden personenbezogenen Daten stattfindet.
            <br /><br />
            Landesbeauftragte für Datenschutz und Informationsfreiheit Nordrhein-Westfalen<br />
            Postfach 20 04 44<br />
            40102 Düsseldorf<br />
            <a href="https://www.ldi.nrw.de">www.ldi.nrw.de</a><br />
            Tel: +49 (0) 211 38424-0<br />
            Fax: +49 (0) 211 38424-10<br />
            E-Mail: <a href="mailto:poststelle@ldi.nrw.de">poststelle@ldi.nrw.de</a>
          </p>

          <h2>Automatische Speicherung von Daten</h2>
          <p>
            Bei jedem Zugriff auf die Informations-und Dienstleistungsdatenbank NRW werden dort vorübergehend Daten über so genannte Protokoll- oder Log-Dateien gespeichert, die möglicherweise eine Identifizierung zulassen.<br /><br />
            Die folgenden Daten werden erhoben: <br /><br />

            Die folgenden Daten werden bei jedem Aufruf der Internetseite erhoben:

            <ul>
              <li>IP-Adresse</li>
            </ul>

            Die Daten aus den Protokoll- bzw. Log-Dateien werden bei der Nutzeranfrage gesendet und werden bis zu 24 Stunden und ausschließlich für Administratoren zugänglich aufbewahrt. Danach werden die Logs endgültig gelöscht.
          </p>

          <h2>Nutzung von Cookies</h2>
          <p>
            Derzeit werde keine Cookies eingesetzt.
          </p>

          <h2>Webanalyse</h2>
          <p>
            Derzeit wird kein Instrument zur Webanalyse eingesetzt.
          </p>

          <h2>Weitergabe personenbezogener Daten</h2>
          <p>
            Personenbezogene Daten werden nicht gespeichert oder weitergegeben.
          </p>

          <h2>SSL-bzw. TLS-Verschlüsselung</h2>
          <p>
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
          </p>

          <h2>Allgemeines zum Datenschutz</h2>
          <p>
            Allgemeines zum Thema Datenschutz finden Sie auf der Website der Landesbeauftragten für Datenschutz und Informationsfreiheit Nordrhein-Westfalen: <a href="https://www.ldi.nrw.de">www.ldi.nrw.de</a>
          </p>
        </Content>
      </Layout>
    );
  }
}

export default PrivacyLayout;
