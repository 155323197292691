import { Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { Deadline } from '../services/validator/ZuFi';
import PrettyValueOrDefault from './PrettyValueOrDefault';

type DeadlineProps = {
  deadline: Deadline,
};

type DeadlineState = {

};

type DeadlinePropDetailsType = {
  type: TooltipDetails,
  deadlinePeriod: TooltipDetails,
  deadlineUnit: TooltipDetails,
  deadlinePeriodUntil: TooltipDetails,
  deadlineUnitUntil: TooltipDetails,
  fromDate: TooltipDetails,
  fromMonthDay: TooltipDetails,
  fromDay: TooltipDetails,
  untilDate: TooltipDetails,
  untilMonthDay: TooltipDetails,
  untilDay: TooltipDetails,
  description: TooltipDetails,
  position: TooltipDetails,
  url: TooltipDetails,
  validFrom: TooltipDetails,
  validUntil: TooltipDetails,
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const DeadlinePropDetails: DeadlinePropDetailsType = {
  type: {
    label: 'Typ',
    tooltip: {
      title: 'frist > typ',
      content: undefined,
    },
  },
  deadlinePeriod: {
    label: 'Dauer',
    tooltip: {
      title: 'frist > dauer',
      content: undefined,
    },
  },
  deadlineUnit: {
    label: 'Einheit der Dauer',
    tooltip: {
      title: 'frist > einheit > code',
      content: undefined,
    },
  },
  deadlinePeriodUntil: {
    label: 'Dauer bis',
    tooltip: {
      title: 'frist > dauerBist',
      content: undefined,
    },
  },
  deadlineUnitUntil: {
    label: 'Einheit der Bis-Dauer',
    tooltip: {
      title: 'frist > einheitBis',
      content: undefined,
    },
  },
  fromDate: {
    label: 'Start Datum',
    tooltip: {
      title: 'frist > von > datum',
      content: undefined,
    },
  },
  fromMonthDay: {
    label: 'Start Monat-Tag',
    tooltip: {
      title: 'frist > von > monatTag',
      content: undefined,
    },
  },
  fromDay: {
    label: 'Start Tag',
    tooltip: {
      title: 'frist > von > tag',
      content: undefined,
    },
  },
  untilDate: {
    label: 'End Datum',
    tooltip: {
      title: 'frist > bis > datum',
      content: undefined,
    },
  },
  untilMonthDay: {
    label: 'End Monat-Tag',
    tooltip: {
      title: 'frist > bis > monatTag',
      content: undefined,
    },
  },
  untilDay: {
    label: 'End Tag',
    tooltip: {
      title: 'frist > bis > tag',
      content: undefined,
    },
  },
  description: {
    label: 'Beschreibung',
    tooltip: {
      title: 'frist > beschreibung',
      content: undefined,
    },
  },
  position: {
    label: 'Position Darstellung',
    tooltip: {
      title: 'frist > positionDarstellung',
      content: undefined,
    },
  },
  url: {
    label: 'Weiterführender Link',
    tooltip: {
      title: 'frist > weiterfuehrenderLink',
      content: undefined,
    },
  },
  validFrom: {
    label: 'Gültig ab',
    tooltip: {
      title: 'frist > gueltigkeit > beginn',
      content: undefined,
    },
  },
  validUntil: {
    label: 'Gültig bis',
    tooltip: {
      title: 'frist > gueltigkeit > ende',
      content: undefined,
    },
  },
};

class DeadlineComponent extends React.Component<DeadlineProps, DeadlineState> {
  private descriptionProps(key: string): any {
    const details: TooltipDetails = DeadlinePropDetails[key as keyof DeadlinePropDetailsType];

    if (details === undefined) {
      return {};
    }

    return {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };
  }

  public render(): React.ReactNode {
    return (
      <div className="leistung">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('type')}>
            <PrettyValueOrDefault value={this.props.deadline.type} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('deadlinePeriod')}>
            <PrettyValueOrDefault value={this.props.deadline.deadlinePeriod} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('deadlineUnit')}>
            <PrettyValueOrDefault value={this.props.deadline.deadlineUnit} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('deadlinePeriodUntil')}>
            <PrettyValueOrDefault value={this.props.deadline.deadlinePeriodUntil} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('deadlineUnitUntil')}>
            <PrettyValueOrDefault value={this.props.deadline.deadlineUnitUntil} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('fromDate')}>
            <PrettyValueOrDefault value={this.props.deadline.fromDate} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('fromMonthDay')}>
            <PrettyValueOrDefault value={this.props.deadline.fromMonthDay} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('fromDay')}>
            <PrettyValueOrDefault value={this.props.deadline.fromDay} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('untilDate')}>
            <PrettyValueOrDefault value={this.props.deadline.untilDate} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('untilMonthDay')}>
            <PrettyValueOrDefault value={this.props.deadline.untilMonthDay} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('untilDay')}>
            <PrettyValueOrDefault value={this.props.deadline.untilDay} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('description')}>
            <PrettyValueOrDefault value={this.props.deadline.description} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('position')}>
            <PrettyValueOrDefault value={this.props.deadline.position} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('url')}>
            <PrettyValueOrDefault value={this.props.deadline.url} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validFrom')}>
            <PrettyValueOrDefault value={this.props.deadline.validFrom} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validUntil')}>
            <PrettyValueOrDefault value={this.props.deadline.validUntil} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default DeadlineComponent;
