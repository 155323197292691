import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { ProcessingTime } from '../services/validator/ZuFi';
import DeadlineComponent from './Deadline';
import DefaultValue from './DefaultValue';
import PrettyValueOrDefault from './PrettyValueOrDefault';

const { Panel } = Collapse;

type ProcessingTimeProps = {
  processingTime: ProcessingTime;
};

type ProcessingTimeState = {

};

type ProcessingTimePropDetailsType = {
  id: TooltipDetails,
  position: TooltipDetails,
  secondaryID: TooltipDetails,
  validFrom: TooltipDetails,
  validUntil: TooltipDetails,
  processingPeriod: TooltipDetails,
  description: TooltipDetails,
  url: TooltipDetails,
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const ProcessingTimePropDetails: ProcessingTimePropDetailsType = {
  id: {
    label: 'ID',
    tooltip: {
      title: 'id',
      content: undefined,
    },
  },
  position: {
    label: 'Position',
    tooltip: {
      title: 'positionDarstellung',
      content: undefined,
    },
  },
  secondaryID: {
    label: 'Sekundäre ID',
    tooltip: {
      title: 'idSekundaer',
      content: undefined,
    },
  },
  validFrom: {
    label: 'Gültig ab',
    tooltip: {
      title: 'gueltigkeit > beginn',
      content: undefined,
    },
  },
  validUntil: {
    label: 'Gültig bis',
    tooltip: {
      title: 'gueltigkeit > ende',
      content: undefined,
    },
  },
  processingPeriod: {
    label: 'Bearbeitungsdauer',
    tooltip: {
      title: 'bearbeitungsdauer',
      content: undefined,
    },
  },
  description: {
    label: 'Beschreibung',
    tooltip: {
      title: 'beschreibung',
      content: undefined,
    },
  },
  url: {
    label: 'Weiterführender Link',
    tooltip: {
      title: 'weiterfuehrenderLink',
      content: undefined,
    },
  },
};

class ProcessingTimeComponent extends React.Component<ProcessingTimeProps, ProcessingTimeState> {
  private descriptionProps(key: string): any {
    const details: TooltipDetails = ProcessingTimePropDetails[key as keyof ProcessingTimePropDetailsType];

    if (details === undefined) {
      return {};
    }

    return {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };
  }

  public render(): React.ReactNode {
    return (
      <div className="leistung">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('id')}>
            <PrettyValueOrDefault value={this.props.processingTime.id} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('position')}>
            <PrettyValueOrDefault value={this.props.processingTime.position} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('secondaryID')}>
            <PrettyValueOrDefault value={this.props.processingTime.secondaryID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validFrom')}>
            <PrettyValueOrDefault value={this.props.processingTime.validFrom} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validUntil')}>
            <PrettyValueOrDefault value={this.props.processingTime.validUntil} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('processingPeriod')}>
            { typeof this.props.processingTime.processingPeriod === 'string'
              ? <DefaultValue />
              : <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                ghost
              >
                <Panel
                  header={(
                    <span>{
                      this.props.processingTime.processingPeriod.description
                        ? `${this.props.processingTime.processingPeriod.description} (Frist)`
                        : 'Frist'
                    }</span>
                  )}
                  key={`processingTime-period-${this.props.processingTime.id}`}
                  className="no-padding"
                >
                  <DeadlineComponent deadline={this.props.processingTime.processingPeriod} />
                </Panel>
              </Collapse>
            }
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('description')}>
            <PrettyValueOrDefault value={this.props.processingTime.description} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('url')}>
            <PrettyValueOrDefault value={this.props.processingTime.url} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default ProcessingTimeComponent;
