export enum ValidationErrorLevel {
  ERROR = 'error',
  WARNING = 'warning',
}

export type ValidationError = {
  code: number;
  message: string;
  line: number;
  errorCodeLevel: ValidationErrorLevel;
}
