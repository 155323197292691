import React, { ReactNode } from 'react';
import sanitizeHtml from 'sanitize-html';

import DefaultValue from './DefaultValue';

type PrettyResultTableValueProps = {
  value: string;
};

class PrettyValueOrDefault extends React.Component<PrettyResultTableValueProps> {
  private stripNotAllowedXzufiTags(html: string): string {
    return sanitizeHtml(html, {
      allowedTags: ['a', 'b', 'br', 'em', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'i', 'li', 'ol', 'p', 'strong', 'sub',
        'sup', 'ul'],
      allowedAttributes: { 'a': ['href'] },
      allowedSchemes: ['ftp', 'http', 'https', 'mailto'],
    });
  }

  public render(): ReactNode {
    if (this.props.value === '') {
      return <DefaultValue />
    }

    return (<span dangerouslySetInnerHTML={{ __html: this.stripNotAllowedXzufiTags(this.props.value) }}></span>);
  }
}

export default PrettyValueOrDefault;
