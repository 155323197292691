import './App.css';

import BaseLayout from './views/BaseLayout';

function App() {
  return (
    <BaseLayout />
  );
}

export default App;
