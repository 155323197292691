import { CaretRightOutlined } from '@ant-design/icons';
import { Collapse, Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { DocumentModule } from '../services/validator/ZuFi';
import DefaultValue from './DefaultValue';
import DocumentComponent from './Document';
import PrettyValueOrDefault from './PrettyValueOrDefault';

const { Panel } = Collapse;

type DocumentModuleProps = {
  documentModule: DocumentModule;
}

type DocumentModuleState = {

}

type DeadlinePropDetailsType = {
  documentIn: TooltipDetails,
  documentOut: TooltipDetails,
  description: TooltipDetails,
  url: TooltipDetails,
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const DeadlinePropDetails: DeadlinePropDetailsType = {
  documentIn: {
    label: 'Eingehendes Dokument',
    tooltip: {
      title: 'dokumentEingehend',
      content: undefined,
    },
  },
  documentOut: {
    label: 'Ausgehendes Dokument',
    tooltip: {
      title: 'dokumentAusgehend',
      content: undefined,
    },
  },
  description: {
    label: 'Beschreibung',
    tooltip: {
      title: 'beschreibung',
      content: undefined,
    },
  },
  url: {
    label: 'Weiterführender Link',
    tooltip: {
      title: 'weiterfuehrenderLink',
      content: undefined,
    },
  },
};

class DocumentModuleComponent extends React.Component<DocumentModuleProps, DocumentModuleState> {
  private descriptionProps(key: string): any {
    const details: TooltipDetails = DeadlinePropDetails[key as keyof DeadlinePropDetailsType];

    if (details === undefined) {
      return {};
    }

    return {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };
  }

  public render(): ReactNode {
    return (
      <div className="leistung">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('documentIn')}>
            { typeof this.props.documentModule.documentIn === 'string'
              ? <DefaultValue />
              : <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                ghost
              >
                <Panel
                  header={(
                    <span>{
                      this.props.documentModule.documentIn.name
                        ? `${this.props.documentModule.documentIn.name} (Dokument)`
                        : 'Dokument'
                    }</span>
                  )}
                  key={`doc-${this.props.documentModule.documentIn.name}`}
                  className="no-padding"
                >
                  <DocumentComponent document={this.props.documentModule.documentIn} />
                </Panel>
              </Collapse>
            }
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('documentOut')}>
            { typeof this.props.documentModule.documentOut === 'string'
              ? <DefaultValue />
              : <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                ghost
              >
                <Panel
                  header={(
                    <span>{
                      this.props.documentModule.documentOut.name
                        ? `${this.props.documentModule.documentOut.name} (Dokument)`
                        : 'Dokument'
                    }</span>
                  )}
                  key={`doc-${this.props.documentModule.documentOut.name}`}
                  className="no-padding"
                >
                  <DocumentComponent document={this.props.documentModule.documentOut} />
                </Panel>
              </Collapse>
            }
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('description')}>
            <PrettyValueOrDefault value={this.props.documentModule.description} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('url')}>
            <PrettyValueOrDefault value={this.props.documentModule.url} />
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  }
}

export default DocumentModuleComponent;
