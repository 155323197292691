import './Service.css';

import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Collapse, Descriptions, Popover } from 'antd';
import React, { ReactNode } from 'react';

import { Address, Communication, ContactPerson, OrgUnit } from '../services/validator/ZuFi';
import AddressComponent from './Address';
import AuthorComponent from './Author';
import CommunicationComponent from './Communication';
import ContactPersonComponent from './ContactPerson';
import DefaultValue from './DefaultValue';
import PrettyValueOrDefault from './PrettyValueOrDefault';

const { Panel } = Collapse;

type OrgUnitComponentProps = {
  orgUnit: OrgUnit;
  key: number;
  index: number;
  searchTerm: string;
}

type OrgUnitComponentState = {
  allProperties: boolean;
}

type OrgUnitPropDetailsType = {
  id: TooltipDetails;
  secondaryID: TooltipDetails;
  parentOrgUnitID: TooltipDetails;
  childOrgUnitID: TooltipDetails;
  name: TooltipDetails;
  description: TooltipDetails;
  shortDescription: TooltipDetails;
  infoOpeningHoursText: TooltipDetails;
  infoParkplatz: TooltipDetails;
  infoOEPNV: TooltipDetails;
  infoBarrierefreiheit: TooltipDetails;
  labelElevator: TooltipDetails;
  labelAccessible: TooltipDetails;
  website: TooltipDetails;
  creditorID: TooltipDetails;
  paymentMethod: TooltipDetails;
  paymentMethodDescription: TooltipDetails;
  synonym: TooltipDetails;
  contactPerson: TooltipDetails;
  address: TooltipDetails;
  communication: TooltipDetails;
  gebietID: TooltipDetails;
  rolle: TooltipDetails;
  modifiedAt: TooltipDetails;
  validFrom: TooltipDetails;
  validUntil: TooltipDetails;
  author: TooltipDetails;
  language: TooltipDetails;
  languageCode: TooltipDetails;
}

type TooltipDetails = {
  label: string;
  tooltip: {
    title: string;
    content: ReactNode;
  };
}

const OrgUnitPropDetails: OrgUnitPropDetailsType = {
  id: {
    label: 'ID',
    tooltip: {
      title: 'id [1]',
      content: 'Eindeutige ID der Leistung',
    },
  },
  name: {
    label: 'Name',
    tooltip: {
      title: 'name [1]',
      content: 'Namen der Organisation.',
    },
  },
  description: {
    label: 'Beschreibung',
    tooltip: {
      title: 'beschreibung [0…n]',
      content: (
        <div>
          Allgemeine Beschreibung der OE. Soll nicht für Leistungsbeschreibungen, Zuständigkeiten verwendet werden.<br />

          Die Verwendung bestimmter HTML-Auszeichnungen (siehe Spezifikation) ist hier gestattet.
        </div>
      ),
    },
  },
  shortDescription: {
    label: 'Kurzbeschreibung',
    tooltip: {
      title: 'kurzbeschreibung [0…n]',
      content: 'Kurze Beschreibung der OE.',
    },
  },
  infoOpeningHoursText: {
    label: 'Info Öffnungszeiten',
    tooltip: {
      title: 'infoOeffnungszeitenText [0…n]',
      content: (
        <div>
          Hinweise zu den Öffnungszeiten. Kann sowohl ein einfacher Text, als auch HTML (bspw. eine Tabelle) sein.
        </div>
      ),
    },
  },
  infoParkplatz: {
    label: 'Info Parkplatz',
    tooltip: {
      title: 'infoParkplatz [0…n]',
      content: (
        <div>
          Angaben zu Parkplätzen. Diese Information bezieht sich in der Regel auf die Besuchsanschrift der Organisationseinheit.<br />

          Die Verwendung bestimmter HTML-Auszeichnungen (siehe Spezifikation) ist hier gestattet.
        </div>
      ),
    },
  },
  infoOEPNV: {
    label: 'Info OEPNV',
    tooltip: {
      title: 'infoOEPNV [0…n]',
      content: (
        <div>
          Angaben zur Erreichbarkeit mit dem ÖPNV. Diese Information bezieht sich in der Regel auf die Besuchsanschrift der Organisationseinheit.<br />

          Die Verwendung bestimmter HTML-Auszeichnungen (siehe Spezifikation) ist hier gestattet.
        </div>
      ),
    },
  },
  infoBarrierefreiheit: {
    label: 'Info Barrierefreiheit',
    tooltip: {
      title: 'infoBarrierefreiheit [0…n]',
      content: (
        <div>
          Angaben zur Barrierefreiheit der OE. Diese Information bezieht sich in der Regel auf die Besuchsanschrift der Organisationseinheit.<br />

          Die Verwendung bestimmter HTML-Auszeichnungen (siehe Spezifikation) ist hier gestattet.
        </div>
      ),
    },
  },
  labelAccessible: {
    label: 'Kennzeichen Rollstuhlgerecht',
    tooltip: {
      title: 'kennzeichenRollstuhlgerecht [0…1]',
      content: 'Gibt an, ob die Organisationseinheit mit einem Rollstuhl zugänglich ist. ',
    },
  },
  contactPerson: {
    label: 'Kontaktperson',
    tooltip: {
      title: 'kontaktperson [0…n]',
      content: 'Kontaktperson ist eine natürliche Person, welche eine Aufgabe im Kontext einer Organisationseinheit durchführt. Im Allgemeinen auch ein Mitarbeiter.',
    },
  },
  address: {
    label: 'Anschrift',
    tooltip: {
      title: 'anschrift [0…n]',
      content: 'Zugeordnete Anschriften, wie z.B. Besuchsanschrift oder Postanschrift. Hat eine Organisationseinheit mehrere Standorte, sollte dies mit weiteren untergeordneten Organisationseinheiten abgebildet werden.',
    },
  },
  communication: {
    label: 'Kommunikation',
    tooltip: {
      title: 'kommunikation [0…n]',
      content: 'Datentyp für Angaben zu Kommunikationskanälen, wie Telefon, Fax, E-Mail.',
    },
  },
  gebietID: {
    label: 'Regionalschlüssel',
    tooltip: {
      title: 'gebietID [1…n]',
      content: 'Amtlicher Regionalschlüssel. Kann von rechts gekürzt werden, um bspw. das Land, einen Kreis oder eine kreisfreie Stadt anzugeben.',
    },
  },
  rolle: {
    label: 'Rolle',
    tooltip: {
      title: 'rolle [0…1]',
      content: (
        <div>
          <p>
            Wenn die Eigenschaft "rolle" ausgelassen wird, gilt der Eintrag als Zuständige Stelle und Ansprechpunkt. Die folgenden expliziten Werte werden unterstützt:<br /><br />

            01 → Zuständige Stelle und Ansprechpunkt<br />
            02 → Zuständige Stelle<br />
            03 → Ansprechpunkt<br /><br />

            Siehe: urn:de:xzufi:codeliste:zustaendigkeitsrolle
          </p>
        </div>
      ),
    },
  },
  secondaryID: {
    label: 'Sekundäre ID',
    tooltip: {
      title: 'idSekundaer [0…n]',
      content: '',
    },
  },
  parentOrgUnitID: {
    label: 'Übergeordnete Organisationseinheit',
    tooltip: {
      title: 'uebergeordneteOrganisationseinheitID [0…1]',
      content: '',
    },
  },
  childOrgUnitID: {
    label: 'Untergeordnete Organisationseinheit',
    tooltip: {
      title: 'untergeordneteOrganisationseinheitID [0…n]',
      content: '',
    },
  },
  labelElevator: {
    label: 'Kennzeichen Aufzug',
    tooltip: {
      title: 'kennzeichenAufzug [0…1]',
      content: '',
    },
  },
  website: {
    label: 'Webseite',
    tooltip: {
      title: 'internetadresse [0…n]',
      content: '',
    },
  },
  creditorID: {
    label: 'Gläubiger ID',
    tooltip: {
      title: 'glaeubigeridentifikationsnummer [0…1]',
      content: '',
    },
  },
  paymentMethod: {
    label: 'Zahlungsweisen',
    tooltip: {
      title: 'zahlungsweisen [0…n]',
      content: 'Angabe unterstützter Zahlungsweisen in dieser OE',
    },
  },
  paymentMethodDescription: {
    label: 'Zahlungsweisen Text',
    tooltip: {
      title: 'zahlungsweisenText [0…n]',
      content: 'Angaben zu erlaubten Zahlungsweisen als Freitext. Es ist die Angabe der Codes zu bevorzugen.',
    },
  },
  synonym: {
    label: 'Synonyme',
    tooltip: {
      title: 'synonym [0…n]',
      content: '',
    },
  },
  modifiedAt: {
    label: 'Zuletzt geändert am',
    tooltip: {
      title: 'versionsinformation > geaendertDatumZeit [0…1]',
      content: 'Datum und Uhrzeit der letzten Änderung',
    },
  },
  validFrom : {
    label: 'Gültigkeitsbeginn',
    tooltip: {
      title: 'gueltigkeit > beginn [0…n]',
      content: 'Beginn der Gültigkeit',
    },
  },
  validUntil : {
    label: 'Gültigkeitsende',
    tooltip: {
      title: 'gueltigkeit > ende [0…n]',
      content: 'Ende der Gültigkeit',
    },
  },
  author : {
    label: 'Herausgeber',
    tooltip: {
      title: 'herausgeber [0…1]',
      content: 'Der Herausgeber',
    },
  },
  language : {
    label: 'Sprachbezeichnung (Deutsch)',
    tooltip: {
      title: 'sprachversion > sprachbezeichnungDeutsch [1]',
      content: 'Bezeichnung der Sprach in Deutsch, z.B. "Chinesisch"',
    },
  },
  languageCode : {
    label: 'Code der Sprache',
    tooltip: {
      title: 'sprachversion > languageCode [1]',
      content: (
        <div>
          Code der Sprache der Sprachversion. Abgebildet mit W3C-Datentyp language (https://www.w3.org/TR/xmlsche-ma-2/#language). Zum Beispiel "en", "en-US", "de", "de-DE" oder "zh-cn".
        </div>
      ),
    },
  },
};

class OrgUnitComponent extends React.Component<OrgUnitComponentProps, OrgUnitComponentState> {
  public constructor(props: OrgUnitComponentProps) {
    super(props);

    this.state = {
      allProperties: false,
    };

    this.setAllProperties = this.setAllProperties.bind(this);
  }

  public componentDidUpdate(prevProps: OrgUnitComponentProps) {
    if (prevProps.searchTerm.length !== 0 && this.props.searchTerm.length === 0 && this.state.allProperties) {
      this.setState({
        allProperties: false,
      });
    }

    if (this.props.searchTerm.length !== 0 && !this.state.allProperties) {
      this.setState({
        allProperties: true,
      });
    }
  }

  private descriptionProps(key: string): any {
    const details: TooltipDetails = OrgUnitPropDetails[key as keyof OrgUnitPropDetailsType];

    if (details === undefined) {
      return {};
    }

    let props: any = {
      label: (
        <Popover placement="topLeft"
          title={details.tooltip.title}
          content={details.tooltip.content}
        >
          {details.label}
        </Popover>
      ),
    };

    if (this.props.searchTerm === '') {
      return props;
    }

    const value = this.props.orgUnit[key as keyof OrgUnit];
    const valueMatching = JSON.stringify(value).toLowerCase().includes(this.props.searchTerm.toLowerCase());
    const labelMatching = details.label.toLowerCase().includes(this.props.searchTerm.toLowerCase());

    if (!labelMatching && !valueMatching) {
      props = {
        ...props,
        style: {
          display: 'none',
        },
      };
    }

    return props;
  }

  private setAllProperties(expanded: boolean) {
    this.setState({
      allProperties: expanded,
    });
  }

  public render(): React.ReactNode {
    return (
      <div className="leistung">
        <Descriptions column={1}>
          <Descriptions.Item className="details-row" {...this.descriptionProps('id')}>
            <PrettyValueOrDefault value={this.props.orgUnit.id} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('name')}>
            <PrettyValueOrDefault value={this.props.orgUnit.name} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('description')}>
            <PrettyValueOrDefault value={this.props.orgUnit.description} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('shortDescription')}>
            <PrettyValueOrDefault value={this.props.orgUnit.shortDescription} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('website')}>
            <PrettyValueOrDefault value={this.props.orgUnit.website} />
          </Descriptions.Item>
        </Descriptions>
        {!this.state.allProperties && <Button
          type="primary"
          ghost
          onClick={() => {
            this.setAllProperties(true);
          }}
        >
          Alle Felder der Leistung anzeigen
        </Button>}
        <Descriptions
          column={1}
          style={{
            transition: 'ease .5s',
          }}
          className={this.state.allProperties ? '' : 'hidden'}
        >
          <Descriptions.Item className="details-row" {...this.descriptionProps('secondaryID')}>
            <PrettyValueOrDefault value={this.props.orgUnit.secondaryID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('parentOrgUnitID')}>
            <PrettyValueOrDefault value={this.props.orgUnit.parentOrgUnitID} />
          </Descriptions.Item>
          <Descriptions.Item className="multiline details-row" {...this.descriptionProps('childOrgUnitID')}>
            {this.props.orgUnit.childOrgUnitID.map((childOrgUnitID: string, key: number) => (
              <span key={key}>{childOrgUnitID}<br /></span>
            ))}
            {this.props.orgUnit.childOrgUnitID.length === 0 && <DefaultValue />}
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('infoOpeningHoursText')}>
            <PrettyValueOrDefault value={this.props.orgUnit.infoOpeningHoursText} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('labelElevator')}>
            <PrettyValueOrDefault value={this.props.orgUnit.labelElevator} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('labelAccessible')}>
            <PrettyValueOrDefault value={this.props.orgUnit.labelAccessible} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('address')}>
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              ghost
            >
              { this.props.orgUnit.address.map((address: Address, addressIndex: number) => (
                <Panel
                  header={(
                    <span>{`Anschrift ${addressIndex + 1}`}</span>
                  )}
                  key={`addr-${addressIndex}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <AddressComponent address={address} key={addressIndex} index={addressIndex} searchTerm={this.props.searchTerm} />
                  </div>
                </Panel>
              )) }
              {this.props.orgUnit.address.length === 0 && <DefaultValue />}
            </Collapse>
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('communication')}>
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              ghost
            >
              { this.props.orgUnit.communication.map((communication: Communication, index: number) => (
                <Panel
                  header={(
                    <span>{
                      communication.identifier
                        ? `${communication.identifier} (Kommunikation ${index + 1})`
                        : `Kommunikation ${index + 1}`
                    }</span>
                  )}
                  key={`comm-${index}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <CommunicationComponent communication={communication} key={index} index={index} />
                  </div>
                </Panel>
              )) }
              {this.props.orgUnit.communication.length === 0 && <DefaultValue />}
            </Collapse>
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('creditorID')}>
            <PrettyValueOrDefault value={this.props.orgUnit.creditorID} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('paymentMethod')}>
            <PrettyValueOrDefault value={this.props.orgUnit.paymentMethod} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('paymentMethodDescription')}>
            <PrettyValueOrDefault value={this.props.orgUnit.paymentMethodDescription} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('synonym')}>
            <PrettyValueOrDefault value={this.props.orgUnit.synonym} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('modifiedAt')}>
            <PrettyValueOrDefault value={this.props.orgUnit.modifiedAt} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('contactPerson')}>
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
              ghost
            >
              { this.props.orgUnit.contactPerson.map((contactPerson: ContactPerson, contactIndex: number) => (
                <Panel
                  header={(
                    <span>{
                      contactPerson.lastName
                        ? `${contactPerson.lastName} (Kommunikation ${contactIndex + 1})`
                        : `Kommunikation ${contactIndex + 1}`
                    }</span>
                  )}
                  key={`addr-${contactIndex}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <ContactPersonComponent contactPerson={contactPerson} key={contactIndex} index={contactIndex} searchTerm={this.props.searchTerm} />
                  </div>
                </Panel>
              )) }
              {this.props.orgUnit.contactPerson.length === 0 && <DefaultValue />}
            </Collapse>
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validFrom')}>
            <PrettyValueOrDefault value={this.props.orgUnit.validFrom} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('validUntil')}>
            <PrettyValueOrDefault value={this.props.orgUnit.validUntil} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('author')}>
            { typeof this.props.orgUnit.author === 'string'
              ? <DefaultValue />
              : <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                ghost
              >
                <Panel
                  header={(
                    <span>{
                      this.props.orgUnit.author.name
                        ? `${this.props.orgUnit.author.name} (Herausgeber)`
                        : 'Herausgeber'
                    }</span>
                  )}
                  key={`author-${this.props.orgUnit.id}`}
                  className="no-padding"
                >
                  <div className="ml-24">
                    <AuthorComponent author={this.props.orgUnit.author} />
                  </div>
                </Panel>
              </Collapse>
            }
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('language')}>
            <PrettyValueOrDefault value={this.props.orgUnit.language} />
          </Descriptions.Item>
          <Descriptions.Item className="details-row" {...this.descriptionProps('languageCode')}>
            <PrettyValueOrDefault value={this.props.orgUnit.languageCode} />
          </Descriptions.Item>
        </Descriptions>
        {this.state.allProperties && <
          Button
          onClick={() => {
            this.setAllProperties(false);
          }}
        >
          Weniger Felder anzeigen
        </Button>}
      </div>
    );
  }
}

export default OrgUnitComponent;
