import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist-indexeddb-storage';

import rulesReducer from '../components/RuleSet/duck/reducer';
import validatorReducer from '../components/XZuFiResult/duck/reducer';
import systemReducer from './system/reducer';

const persistConfig = {
  key: 'redux-state',
  storage: storage('redux-state'),
}

const createRootReducer = combineReducers({
  rules: rulesReducer,
  validator: validatorReducer,
  system: systemReducer,
});

export default persistReducer(persistConfig, createRootReducer);
